import React from 'react';
import cls from 'classnames';
import { IconName } from '../../icons/types';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
import styles from './Sticker.module.scss';

export interface StickerProps {
	title: string;
	/**
	 * Choose the size of the sticker
	 */
	size?: 'small' | 'medium' | 'large';
	className?: string;
	icon?: IconName;
}
export const Sticker: React.FC<StickerProps> = ({ title, size = 'medium', className, icon }) => {
	return (
		<div className={cls(styles.container, styles[`sticker-container--${size}`], className)}>
			<div className={cls(styles.sticker, icon && styles.stickerWithIcon, styles[`sticker--${size}`])}>
				{icon && (
					<div className={styles.circle}>
						<Icon name={icon} color={'--text-dark'} />
					</div>
				)}
				<Heading
					as={'div'}
					title={title}
					marginBottom="none"
					className={cls(styles.heading, styles[`heading--${size}`])}
				/>
			</div>
		</div>
	);
};

export default Sticker;
