import { ContainerFluid, Section } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { HeaderCommon, HeaderCommonProps } from '@/bloks/Other';
import { renderRichText } from '@/bloks/RichText';
import { SectionImageStoryblok } from '@/components';
import React, { ReactNode } from 'react';
import styles from './SectionImage.module.scss';

interface BlokProps {
	blok: SectionImageStoryblok;
	meta?: {
		layout?: SectionImageProps['layout'];
		buttonVariant?: 'text';
	};
}

const blokProps = ({ blok, meta }: BlokProps): SectionImageProps => ({
	header: blok.header?.[0] && HeaderCommon.blokProps({ blok: blok.header?.[0] }),
	layout: meta?.layout,
	buttonVariant: meta?.buttonVariant,
	imageGroup: blok?.imageGroup?.map((imageGroup) => (
		<div key={imageGroup._uid} className={styles.imageGroup}>
			<DynamicComponent blok={imageGroup} />
		</div>
	)),
	additionalText: blok.additionalText,
});

interface SectionImageProps {
	header?: HeaderCommonProps;
	/**
	 * Layout of header inside Section
	 */
	layout?: 'left' | 'center';
	buttonVariant?: 'text' | 'filled';
	imageGroup: ReactNode[];
	additionalText?: any;
}

export function SectionImage({
	header,
	layout = 'left',
	buttonVariant = 'text',
	imageGroup,
	additionalText,
}: SectionImageProps) {
	return (
		<ContainerFluid>
			<Section>
				{header && <HeaderCommon {...header} layout={layout} buttonVariant={buttonVariant} marginBottom="xl" />}
				{imageGroup}
				{additionalText && <div className={styles.additionalText}>{renderRichText(additionalText)}</div>}
			</Section>
		</ContainerFluid>
	);
}

SectionImage.blokProps = blokProps;
