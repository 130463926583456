import { ContainerFluid, Heading, Section } from '@/atoms';
import { HeaderCommon } from '@/bloks';
import { Blok } from '@/bloks/Blok';
import {
	HeaderCommonStoryblok,
	ProductCatalogItemStoryblok,
	ProductCatalogPackageItemStoryblok,
	SectionComparisonTableStoryblok,
} from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { useSectionComparisonTableSegmentDescriptions } from '@/contexts/products/SectionComparisonProvider';
import { ThemeProvider } from '@/contexts/theme';
import { getChildTheme, getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import { Fragment, useEffect, useRef, useState } from 'react';
import { PriceBox } from './PriceBox';
import styles from './SectionComparisonTable.module.scss';
import { SegmentRow } from './SegmentRow';

export interface Table {
	name: string[];
	rows: Row[];
	slug: string;
}

export interface Row {
	name: string;
	columns: string[];
}

interface BlokProps {
	blok: SectionComparisonTableStoryblok;
	meta: { zone?: number | undefined; containerFoldoutButton: JSX.Element };
}

const blokProps = ({ blok, meta }: BlokProps): Props => {
	return {
		header: blok?.header,
		theme: blok.theme,
		primary: blok.primary,
		showOrderButtons: blok.showOrderButtons,
		products: blok.products,
		segments: blok.segments,
		containerFoldoutButton: meta.containerFoldoutButton,
		zone: meta?.zone,
	};
};

interface Props {
	header?: HeaderCommonStoryblok[];
	theme?: 'primary' | 'lightGreen';
	primary?: string;
	showOrderButtons?: boolean;
	products: ProductCatalogPackageItemStoryblok[] | ProductCatalogItemStoryblok[] | any[];
	segments: SectionComparisonTableStoryblok['segments'];
	containerFoldoutButton?: JSX.Element;
	zone?: number;
}

export const SectionComparisonTable: Blok<Props, BlokProps> = ({
	header,
	theme,
	primary,
	showOrderButtons,
	products,
	containerFoldoutButton,
	zone,
}) => {
	const { segmentDescriptions, tables } = useSectionComparisonTableSegmentDescriptions();
	const [isSticky, setIsSticky] = useState(false);
	const [isStickyEnd, setIsStickyEnd] = useState(false);
	const [headerHeight, setHeaderHeight] = useState('large');
	const [top, setTop] = useState(0);
	const stickyRef = useRef<HTMLDivElement>(null);
	const productsInTable: any[] = products?.map((product) => product.product);
	const linkButtons = products
		?.filter((product) => {
			if (product?.linkButton != undefined) {
				return { product, linkButton: product.linkButton[0] };
			}
		})
		.map((product) => product.linkButton?.[0]);
	const hasTheme = theme ? theme : 'primary';
	const themeInfo = getThemeInfo(hasTheme);
	const secondaryTheme = hasTheme == 'primary' ? 'secondary' : hasTheme;
	const secondaryThemeInfo = getThemeInfo(secondaryTheme);
	const childTheme = getChildTheme(hasTheme);
	const childThemeInfo = getThemeInfo(childTheme);
	const secondaryChildTheme = getChildTheme(secondaryTheme);
	const secondaryChildThemeInfo = getThemeInfo(secondaryChildTheme);
	let updatePrimary = primary;
	if (hasTheme == 'lightGreen') {
		updatePrimary = undefined;
	}

	useEffect(() => {
		const handleScroll = () => {
			if (stickyRef.current) {
				const stickyRect = stickyRef.current.getBoundingClientRect();
				const containerRect = stickyRef.current.parentElement?.getBoundingClientRect();
				const distanceFromTop = stickyRect.top;
				const heightOfHeader = stickyRect.height;
				if (headerHeight === 'large' && distanceFromTop <= 80) {
					setHeaderHeight('small');
				} else if (distanceFromTop > 80) {
					setHeaderHeight('large');
				}
				setIsSticky(headerHeight == 'small' && distanceFromTop <= 80);
				setTop(heightOfHeader + 32);
				if (containerRect) {
					const containerBottom = containerRect && containerRect?.top + containerRect?.height;
					setIsStickyEnd(stickyRect.bottom >= containerBottom - 89);
				}
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [headerHeight]);

	return (
		<ImpressionTrackingProvider
			value={{
				contentName: `${zone}|SectionComparisonTable`,
				contentTarget: 'multiple interactions possible',
				threshold: 0.1,
			}}
		>
			<ContainerFluid>
				<Section paddingX={{ base: 'md', md: 'xl' }} paddingY={{ base: 'xl', md: '4xl', lg: '5xl' }}>
					{header?.map((header) => (
						<HeaderCommon key={header._uid} {...HeaderCommon.blokProps({ blok: header })} marginBottom="3xl" />
					))}
					<div className={styles.comparisionTableContainer}>
						<div
							className={cls(
								styles.row,
								isSticky && styles.stickyHeader,
								hasTheme != 'primary' && styles.stickyRowNeutral,
							)}
							ref={stickyRef}
						>
							<div
								className={cls(styles.col, styles.hideHeaderBoxOnMobile, hasTheme != 'primary' && styles.removeHeight)}
							></div>
							{productsInTable?.map((product, index) => {
								const isPrimaryFirstColumn = primary === '0';
								const isPrimaryThirdColumn = primary === '2';
								const isIndexZero = index === 0;
								const isIndexOne = index === 1;
								const shouldApplyBorderCentered =
									(!primary && (isIndexZero || isIndexOne)) || (isPrimaryFirstColumn && isIndexOne);
								const shouldApplyBorderFirst = isPrimaryThirdColumn && isIndexZero;
								const shouldApplyBorder =
									(productsInTable.length > 2 && shouldApplyBorderCentered) ||
									shouldApplyBorderFirst ||
									(productsInTable.length === 2 && !primary && isIndexZero);

								const productCatalogData = product?.slug !== undefined;
								let hasProductSlug = false;
								let hasButton = false;
								if (productCatalogData) hasProductSlug = true;
								if (showOrderButtons && linkButtons?.[index]) hasButton = true;

								return (
									<ThemeProvider
										theme={index.toString() === primary ? hasTheme : secondaryTheme}
										key={`table-header-${product?.uuid}`}
									>
										<div
											className={cls(
												styles.col,
												hasProductSlug || hasButton ? styles.addPositionRelative : '',
												hasTheme != 'primary' && styles.whiteBorder,
												headerHeight == 'small' && styles.stickyProducts,
												index.toString() === updatePrimary ? styles.primary : styles.secondary,
												index == 0 && styles.borderTopLeft,
												((productsInTable.length === 2 && index === 1) ||
													(productsInTable.length === 3 && index === 2)) &&
													styles.borderTopRight,
												index.toString() === updatePrimary
													? themeInfo.styles.bgColor
													: secondaryThemeInfo.styles.bgColor,
											)}
										>
											<div
												className={cls(
													headerHeight == 'small' && !hasButton && styles.morePadding,
													styles.headerBoxPadding,
													hasProductSlug || hasButton ? styles.addPaddingBottom : '',
												)}
											>
												<div
													className={cls(
														hasTheme == 'primary' && shouldApplyBorder && styles.borderRight,
														styles.headerAndPriceBox,
													)}
												>
													<Heading
														as="div"
														size="h4"
														title={product?.content?.name}
														marginBottom="none"
														className={cls(
															styles.productHeader,
															styles.textAlignCenter,
															headerHeight == 'small' && styles.stickyHeaderText,
															index.toString() === updatePrimary && styles.primeHeader,
														)}
													/>
													<PriceBox
														product={product}
														meta={{ zone: zone }}
														showOrderButtons={showOrderButtons}
														theme={hasTheme}
														primary={index.toString() === updatePrimary}
														headerHeight={headerHeight}
														linkButton={linkButtons?.[index]}
													/>
												</div>
											</div>
										</div>
									</ThemeProvider>
								);
							})}
						</div>
						<div>
							{tables?.map?.((segment: Table, segmentIndex: number) => {
								return (
									<Fragment key={segmentIndex}>
										<div
											className={cls(styles.row, styles.stickyRow, styles.mobileSegmentRow)}
											style={{
												top: headerHeight == 'small' ? top + 'px' : '0px',
											}}
										>
											<div
												className={cls(styles.col, styles.segmentRowPadding, hasTheme != 'primary' && styles.whiteRow)}
											>
												<Heading as="div" size="h4" title={segment?.name} marginBottom="none" />
											</div>

											{hasTheme == 'primary' &&
												productsInTable?.map((_product, index) => (
													<div
														className={cls(
															styles.col,
															index.toString() === primary ? childThemeInfo.styles.row : secondaryThemeInfo.styles.row,
															styles.hideRowOnMobile,
														)}
														key={index}
													></div>
												))}
										</div>

										{segment?.rows?.map((row: Row, rowIndex: number) => {
											const isLastSegment = segmentIndex === tables.length - 1;
											const isLastRow = rowIndex == segment.rows.length - 1;
											const isLastRowOfLastSegment = isLastSegment && rowIndex === segment.rows.length - 1;
											return (
												<SegmentRow
													key={rowIndex}
													isLastRow={isLastRow}
													isLastRowOfLastSegment={isLastRowOfLastSegment}
													row={row}
													primary={updatePrimary}
													secondaryChildThemeInfo={secondaryChildThemeInfo}
													childThemeInfo={childThemeInfo}
													descriptions={segmentDescriptions}
													tableSlug={segment.slug}
													theme={hasTheme}
													isStickyEnd={isStickyEnd}
												/>
											);
										})}
									</Fragment>
								);
							})}
						</div>
						{containerFoldoutButton && <div className={styles.containerFoldoutButton}>{containerFoldoutButton}</div>}
					</div>
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};

SectionComparisonTable.blokProps = blokProps;
