import { Button, ContainerFluid, Grid, Heading, Icon, Select, SelectOption, Text, TextField, When } from '@/atoms';
import { VatCalculatorStoryblok } from '@/components';
import { cls } from '@/utils';
import { handleFormSubmitEvent } from '@/utils/piwik';
import { getStoryContent } from '@/utils/storyblok';
import { Chart, registerables } from 'chart.js';
import React, { useEffect, useRef, useState } from 'react';
import { t } from 'ttag';
import { CalculationResultRow } from '../CalculationResultRow';
import calcStyles from '../CalculatorStyling.module.scss';
import styles from './VatCalculator.module.scss';

const scopedFieldId = (fieldName: string) => `vat_calculator_field_${fieldName}`;

const isNumeric = (value: string | null): boolean => (value != null ? /^-?\d+([.,]\d+)?$/.test(value) : false);
const isEmpty = (value: string | null | undefined): boolean => ['', undefined, null].includes(value);

const fieldIds = {
	price: scopedFieldId('price'),
	vatIncludedInPrice: scopedFieldId('vatIncludedInPrice'),
	vatPercentage: scopedFieldId('vatPercentage'),
};

const VatResult: React.FC<CalculatedVatInformation> = ({ vat, priceIncludingVat, priceExcludingVat }) => {
	const doughnutChartRef = useRef<HTMLCanvasElement>(null);
	const pieChartRef = useRef<HTMLCanvasElement>(null);

	if (registerables) {
		Chart.register(...registerables);
	}

	useEffect(() => {
		const doughnutEl = doughnutChartRef.current;
		const pieEl = pieChartRef.current;

		if (!doughnutEl || !pieEl) {
			return;
		}

		const doughnutCtx = doughnutEl.getContext('2d');
		const pieCtx = pieEl.getContext('2d');

		if (!doughnutCtx || !pieCtx) {
			return;
		}

		const doughnutChartData = [
			{
				label: t`Pris inklusive moms`,
				value: priceIncludingVat || 0,
				color: '#9C54B9',
			},
		];

		const pieChartData = [
			{
				label: t`Moms`,
				value: vat || 0,
				color: '#FE85B5',
			},
			{
				label: t`Pris exklusive moms`,
				value: priceExcludingVat || 0,
				color: '#FEC400',
			},
		];

		const doughnutChart = new Chart(doughnutCtx, {
			type: 'doughnut',
			data: {
				labels: doughnutChartData.map((data) => data.label),
				datasets: [
					{
						data: doughnutChartData.map((data) => data.value),
						backgroundColor: doughnutChartData.map((data) => data.color),
					},
				],
			},
			options: {
				cutout: '80%',
				animation: false,
				elements: {
					arc: {
						borderWidth: 0,
					},
				},

				plugins: {
					tooltip: {
						enabled: false,
					},
					legend: {
						display: false,
					},
				},
			},
		});

		const pieChart = new Chart(pieCtx, {
			type: 'pie',
			data: {
				labels: pieChartData.map((data) => data.label),
				datasets: [
					{
						data: pieChartData.map((data) => data.value),
						backgroundColor: pieChartData.map((data) => data.color),
					},
				],
			},
			options: {
				animation: false,
				elements: {
					arc: {
						borderWidth: 0,
					},
				},
				plugins: {
					tooltip: {
						enabled: false,
					},
					legend: {
						display: false,
					},
				},
			},
		});

		return () => {
			pieChart.destroy();
			doughnutChart.destroy();
		};
	}, [priceIncludingVat, priceExcludingVat, vat]);

	return (
		<div className={calcStyles.description}>
			<Heading as="div" size="h5" className={styles.heading} title={t`Sammanställning`} />

			<div className={styles.chartContainer}>
				<canvas ref={doughnutChartRef} />
				<canvas ref={pieChartRef} />
			</div>

			<CalculationResultRow text={'Moms'} amount={vat} lineColor="pink" size="h3" fontWight="bold" />
			<CalculationResultRow text={'Pris (inklusive moms)'} amount={priceIncludingVat} lineColor="purple" />
			<CalculationResultRow text={'Pris (exklusive moms)'} amount={priceExcludingVat} lineColor="yellow" />
		</div>
	);
};

interface CalculatedVatInformation {
	vat: number | undefined;
	vatPercentage: string;
	vatIncludedInPrice: boolean | string | undefined;
	priceIncludingVat: number | undefined;
	priceExcludingVat: number | undefined;
}

interface Props {
	blok: VatCalculatorStoryblok;
}

export const VatCalculator: React.FC<Props> = ({ blok }) => {
	const [price, setPrice] = useState(0);
	const [formattedPrice, setFormattedPrice] = useState('');
	const [vatIncludedInPrice, setVatIncludedInPrice] = useState<boolean | string | undefined>(true);
	const [vatPercentage, setVatPercentage] = useState('');
	const [calculatedVatInformation, setCalculatedVatInformation] = useState<CalculatedVatInformation>({
		vat: undefined,
		vatPercentage: '',
		vatIncludedInPrice: undefined,
		priceIncludingVat: undefined,
		priceExcludingVat: undefined,
	});
	const calculationText = getStoryContent(blok.calculationText);

	const calculateVat = (e: { preventDefault: () => void }) => {
		e.preventDefault();

		handleFormSubmitEvent({ type: 'calculator', formId: 'vat-calculator' });

		const priceIncludingVat = vatIncludedInPrice ? price : price * (parseInt(vatPercentage) * 0.01 + 1);
		const priceExcludingVat = vatIncludedInPrice ? price * (1 / (parseInt(vatPercentage) * 0.01 + 1)) : price;

		const vatFromPriceIncludingVat = priceIncludingVat - priceExcludingVat;
		const vatFromPriceExcludingVat = priceIncludingVat - priceExcludingVat;
		const vat = vatIncludedInPrice ? vatFromPriceIncludingVat : vatFromPriceExcludingVat;

		setCalculatedVatInformation({
			vat,
			vatPercentage,
			vatIncludedInPrice,
			priceIncludingVat,
			priceExcludingVat,
		});
	};

	const vatIncludedInPriceOptions: SelectOption[] = [
		{ value: 'true', label: t`Inklusive moms`, id: 'vat-included' },
		{ value: 'false', label: t`Exklusive moms`, id: 'vat-excluded' },
	];

	const vatPercentageOptions: SelectOption[] = [
		{ value: '25', label: '25%', id: '25' },
		{ value: '12', label: '12%', id: '12' },
		{ value: '6', label: '6%', id: '6' },
		{ value: '0', label: '0%', id: '0' },
	];

	return (
		<ContainerFluid marginBottom="3xl">
			<div data-testid="vat-calculator">
				<Grid columns={{ base: 1, lg: 2 }}>
					<Grid.Item className={calcStyles.calculator}>
						<div className={calcStyles.headingRow}>
							<Icon name="calculator" />
							<Heading marginBottom="none" as="div" size="h5" className={calcStyles.heading} title={t`Moms`} />
						</div>
						<div className={calcStyles.formContentContainer}>
							<div className={calcStyles.formContainer}>
								<form onSubmit={calculateVat} className={calcStyles.form}>
									<Text marginBottom="2xs" className={styles.label}>{t`Vad har du för pris?`}</Text>
									<TextField
										type="number"
										title={t`Ange pris`}
										size={50}
										id={fieldIds.price}
										value={formattedPrice}
										onChange={(event) => {
											setFormattedPrice(event.target.value);
											setPrice(event.target.value);
										}}
										maxLength={9}
										onFocus={(event) => event.target.select()}
										className={cls(styles.numberInputFields, styles.input)}
									/>
									<Text marginBottom="2xs" className={styles.label}>{t`Är det inklusive eller exklusive moms?`}</Text>
									<Select
										title={t`Välj moms`}
										aria-label={t`Välj moms`}
										onSelect={(value) => setVatIncludedInPrice(value === 'true')}
										className={styles.input}
										data={vatIncludedInPriceOptions}
										selected={
											vatIncludedInPriceOptions.filter(
												(option) => option.value === vatIncludedInPrice?.toString(),
											)?.[0] || null
										}
										onClear={() => setVatIncludedInPrice('')}
										id={fieldIds.vatIncludedInPrice}
										required
										hideXMark
									/>

									<Text marginBottom="2xs" className={styles.label}>{t`Vilken momssats är det?`}</Text>
									<Select
										title={t`Välj momssats`}
										aria-label={t`Välj momssats`}
										onSelect={(value) => setVatPercentage(value)}
										className={styles.input}
										selected={
											vatPercentageOptions.filter((option) => option.value === vatPercentage?.toString())?.[0] || null
										}
										onClear={() => setVatPercentage('')}
										data={vatPercentageOptions}
										id={fieldIds.vatPercentage}
										required
										hideXMark
									/>

									<div className={calcStyles.buttonsContainer}>
										<Button
											aria-label={t`Beräkna`}
											type="submit"
											size="large"
											className={calcStyles.calculateButton}
											disabled={
												isEmpty(price.toString()) ||
												isEmpty(vatPercentage?.toString()) ||
												isEmpty(vatIncludedInPrice?.toString()) ||
												!isNumeric(price.toString()) ||
												price < 1
											}
										>{t`Beräkna`}</Button>
										<div className={calcStyles.clearFieldsBtnContainer}>
											<button
												type="button"
												className={calcStyles.clearFieldsButton}
												onClick={() => {
													setPrice(0);
													setFormattedPrice('');
													setVatIncludedInPrice('');
													setVatPercentage('');
													setCalculatedVatInformation({
														vat: undefined,
														vatPercentage: '',
														vatIncludedInPrice: undefined,
														priceIncludingVat: undefined,
														priceExcludingVat: undefined,
													});
												}}
												data-test-id="employee-calculator_clear-fields"
											>{t`Rensa`}</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</Grid.Item>
					<When truthy={!calculatedVatInformation.priceExcludingVat}>
						<div className={calcStyles.description}>
							{calculationText?.calculationHeader && (
								<span className={calcStyles.calculationHeader}>
									<Heading as="h3" title={`${calculationText?.calculationHeader}`} size="h6" />
								</span>
							)}
							<Text>{calculationText?.calculationDescription}</Text>
						</div>
					</When>
					<When truthy={calculatedVatInformation.priceExcludingVat}>
						<VatResult {...calculatedVatInformation} />
					</When>
				</Grid>
			</div>
		</ContainerFluid>
	);
};
