/* eslint-disable jsx-a11y/alt-text */
import { ImageGridStoryblok } from '@/components';
import { cls } from '@/utils';
import React, { ReactNode } from 'react';
import { Image } from '../Image/Image';
import styles from './ImageGrid.module.scss';

interface BlokProps {
	blok: ImageGridStoryblok;
}

const blokProps = ({ blok }: BlokProps): ImageGridProps => ({
	images: blok.images.map((image) => (
		<Image
			key={image._uid}
			{...Image.blokProps({ blok: image, meta: { fill: true, objectPosition: 'center', objectFit: 'cover' } })}
		/>
	)),
});

export interface ImageGridProps {
	images: ReactNode[];
}

export function ImageGrid({ images }: ImageGridProps) {
	return (
		<div className={cls(styles.gridContainer, styles[`images--${images?.length}`])}>
			{images?.map((image, index) => (
				<div key={index} className={styles[`item--${index + 1}`]}>
					{image}
				</div>
			))}
		</div>
	);
}

ImageGrid.blokProps = blokProps;
