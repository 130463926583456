import { Heading, Text } from '@/atoms';
import { getColor } from '@/colors';
import { SalaryCalculatorStoryblok } from '@/components';
import { getStoryContent } from '@/utils/storyblok';
import { Chart, registerables } from 'chart.js';
import { RefObject, createRef, useCallback, useEffect } from 'react';
import { t } from 'ttag';
import { CalculationResultRow } from '../../CalculationResultRow';
import calcStyles from '../../CalculatorStyling.module.scss';
import { NetPayInfo } from '../SalaryCalculator';
import styles from './SalaryResult.module.scss';

type SalaryResultProps = {
	netPayInfo: NetPayInfo;
	blok: SalaryCalculatorStoryblok;
};

export function SalaryResult({ netPayInfo, blok }: SalaryResultProps) {
	const calculationText = getStoryContent(blok?.calculationText);
	const salaryChartRef: RefObject<HTMLCanvasElement> = createRef();

	if (registerables) {
		Chart.register(...registerables);
	}

	const resizeChart = useCallback(() => {
		salaryChartRef.current!.style.height = '115px';
		salaryChartRef.current!.style.width = '115px';
	}, [salaryChartRef]);

	useEffect(() => {
		if (!salaryChartRef.current) return;
		resizeChart();
	}, [resizeChart, salaryChartRef]);

	useEffect(() => {
		const elem = document.getElementById('salaryChart') as HTMLCanvasElement;

		if (!elem) {
			return;
		}

		const ctx = elem.getContext('2d')!;

		const myChart = new Chart(ctx, {
			type: 'pie',
			...({ circumference: 5 } as any),
			data: {
				labels: [t`Lön efter skatt`, t`Skatt`],
				datasets: [
					{
						data: [netPayInfo.netPay, netPayInfo.taxAmount],
						backgroundColor: [getColor('--accent-pink'), getColor('--accent-yellow')],
					},
				],
			},

			options: {
				animation: false,
				elements: {
					arc: {
						borderWidth: 0,
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					title: {
						display: false,
						text: t`Lön efter skatt`,
					},
				},
				onResize: resizeChart,
			},
		});

		return () => {
			myChart.destroy();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [netPayInfo, salaryChartRef]);

	if (!netPayInfo.netPay) {
		return (
			<div className={calcStyles.description}>
				{calculationText?.calculationHeader && (
					<span className={calcStyles.calculationHeader}>
						<Heading as="h3" title={`${calculationText?.calculationHeader}`} size="h6" />
					</span>
				)}
				<Text>{calculationText?.calculationDescription}</Text>
			</div>
		);
	}

	return (
		<div className={calcStyles.description}>
			<Heading as="div" size="h5" title={t`Så här fördelas lönen`} />
			<canvas id="salaryChart" ref={salaryChartRef} className={styles.pieChart} />
			<CalculationResultRow
				text={'Lön efter skatt (nettolön)'}
				amount={netPayInfo.netPay}
				size="h2"
				decimals={false}
				lineColor="pink"
				fontWight="bold"
			/>
			<CalculationResultRow text={'Skatt'} amount={netPayInfo.taxAmount} lineColor="yellow" decimals={false} />
			<CalculationResultRow text={'Lön före skatt (bruttolön)'} amount={netPayInfo.grossSalary} decimals={false} />
			<CalculationResultRow text={'Arbetsgivaravgift'} amount={netPayInfo.employersDeclaration} decimals={false} />
			<CalculationResultRow
				text={'Arbetsgivaren betalar'}
				amount={
					netPayInfo.grossSalary &&
					netPayInfo.employersDeclaration &&
					parseInt(netPayInfo.grossSalary) + netPayInfo.employersDeclaration
				}
				decimals={false}
			/>
		</div>
	);
}
