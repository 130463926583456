import { Link } from '@/atoms';
import { ButtonVariant, getVariantStyles } from '@/atoms/Button/Button';
import { LinkAnchor } from '@/atoms/LinkAnchor/LinkAnchor';
import { Blok } from '@/bloks/Blok';
import { LinkButtonStoryblok, MultilinkStoryblok } from '@/components';
import { useLightnessTheme, useTheme } from '@/contexts/theme';
import { cls } from '@/utils';
import { normalizeFullSlug } from '@/utils/storyblok';
import { ReactElement } from 'react';
import styles from './LinkButton.module.scss';

/**
 * Builds link props out of a storyblok link
 * Normalizes href
 */
export const buildLinkProps = (link: MultilinkStoryblok): { href: string } => {
	if (!link) {
		return { href: '#' };
	}
	if (link.anchor) {
		const url = normalizeFullSlug(link.url || link.cached_url || '#');
		return { href: `${url}#${link.anchor}` };
	}
	if (link.linktype === 'url') {
		return { href: link.url || link.cached_url || '#' };
	}
	if (link.linktype === 'email') {
		return { href: `mailto:${link.email}` || '#' };
	}
	if (link.linktype === 'asset') {
		return { href: link.url || link.cached_url || '#' };
	}
	if (link.linktype === 'story') {
		return { href: normalizeFullSlug(link.url || link.cached_url) || '#' };
	}
	return { href: '#' };
};

interface BlokProps {
	blok: LinkButtonStoryblok;
	meta?: {
		variant?: string;
		size?: 'small' | 'medium' | 'large';
	};
}

const blokProps = ({ blok, meta }: BlokProps): LinkButtonProps => {
	const linkProps = buildLinkProps(blok.link);
	const isAssetLink = blok.link.linktype === 'asset';
	const isUrlLink = blok.link.linktype === 'url';

	const props: LinkButtonProps = {
		...linkProps,
		type: blok.link.linktype ?? 'url',
		children: blok.text,
		target: blok.link?.target ?? '_self',
	};
	if (meta) {
		const { variant, size } = meta;
		Object.assign(props, { variant, size });
	}
	if (isAssetLink || isUrlLink) {
		props.target = '_blank';
	}
	return props;
};

export interface LinkButtonProps {
	href: string;
	variant?: ButtonVariant;
	size?: 'small' | 'medium' | 'large';
	className?: string;
	target?: '_self' | '_blank';
	rel?: string;
	testID?: string;
	title?: string;
	arrow?: boolean;
	type?: LinkButtonStoryblok['link']['linktype'];
	children: React.ReactNode;
	onClick?: () => void;
	icon?: ReactElement;
	/**
	 * Lightness is used to decide color of elements placed on an image or video
	 */
	applyLightnessTheme?: boolean;
	queryString?: string;
}

/**
 * A Link that looks like a button and has support for icons and theming
 */
export const LinkButton: Blok<LinkButtonProps, BlokProps> = ({
	children,
	className,
	href,
	variant = 'filled',
	size = 'medium',
	target,
	testID,
	title,
	arrow = true,
	type,
	onClick,
	icon,
	applyLightnessTheme = false,
	queryString,
	...rest
}) => {
	let { rel } = rest;

	if (rel == null && target === '_blank') {
		rel = 'noopener noreferrer';
	}

	if (type == 'asset') {
		arrow = false;
	}

	if (type == 'email') {
		href = `mailto:${href}`;
	}

	if (queryString) {
		const hrefHasQuerystring = /\?/.test(href);
		const queryPrefix = hrefHasQuerystring ? '&' : '?';
		href = `${href}${queryPrefix}${queryString}`;
	}

	const lightnessTheme = useLightnessTheme();
	const theme = useTheme();
	const themeInfo = applyLightnessTheme && lightnessTheme ? lightnessTheme : theme;

	const variantStyles = getVariantStyles(variant, themeInfo);

	const sharedProps = {
		href,
		target,
		rel,
		onClick,
		'data-testid': testID,
		title,
		className: cls(
			styles.btn,
			styles[`variant--${variant}`],
			styles[`size--${size}`],
			{ [styles.arrow]: variant === 'text' && arrow && !icon },
			{ [styles.asset]: variant === 'text' && type === 'asset' },
			className,
			variantStyles,
		),
	};

	const hoverEffect = variant === 'text' || variant === 'marklink';

	const content = (
		<span className={styles.inner}>
			{children} {icon}
		</span>
	);

	return hoverEffect ? (
		<LinkAnchor {...sharedProps} hoverEffect={variant === 'text' || variant === 'marklink'}>
			{content}
		</LinkAnchor>
	) : (
		<Link {...sharedProps}>{content}</Link>
	);
};

LinkButton.blokProps = blokProps;
