import Image from 'next/image';

const SIZES = {
	'162.5x35.5': { width: 162.5, height: 35.5 },
	'138x30.14': { width: 138, height: 30.14 },
} as const;

type Props = { size: keyof typeof SIZES };

export const Logo: React.FC<Props> = ({ size }) => {
	const { width, height } = SIZES[size];
	return <Image src="/fortnox-text-logo-white-svg.svg" alt="Fortnox logotyp" priority width={width} height={height} />;
};
