import React, { createContext, useContext, useEffect, useState } from 'react';

export const useGlobalMenu = () => {
	return useContext(Context);
};

interface ContextProps {
	showFirstPanel: boolean;
	setShowFirstPanel: (showFirstPanel: boolean) => void;
	showSecondPanel: boolean;
	setShowSecondPanel: (showSecondPanel: boolean) => void;
	mobileView: boolean;
	setMobileView: (mobileView: boolean) => void;
}

const Context = createContext<ContextProps>({
	showFirstPanel: false,
	setShowFirstPanel: () => {},
	showSecondPanel: false,
	setShowSecondPanel: () => {},
	mobileView: false,
	setMobileView: () => {},
});

interface Props {
	children: React.ReactNode;
}
export const GlobalMenuProvider: React.FC<Props> = ({ children }) => {
	const [showFirstPanel, setShowFirstPanel] = useState(false);
	const [showSecondPanel, setShowSecondPanel] = useState(false);
	const [mobileView, setMobileView] = useState(false);

	useEffect(() => {
		if (!showFirstPanel) {
			setShowSecondPanel(false);
		}
	}, [showFirstPanel]);

	const value = {
		showFirstPanel,
		setShowFirstPanel,
		showSecondPanel,
		setShowSecondPanel,
		mobileView,
		setMobileView,
	};
	return <Context.Provider value={value}>{children}</Context.Provider>;
};
