import { ThemeV2Storyblok } from '@/components';
import { Themes } from '@/types';
import * as careerRawStyles from './styles/career';
import * as conversionRawStyles from './styles/conversion';
import * as fullRawStyles from './styles/full';
import * as globalRawStyles from './styles/global';
import * as lightRawStyles from './styles/light';
import * as lightnessRawStyles from './styles/lightness';
import * as supportRawStyles from './styles/support';

export function getThemeInfo(theme: ThemeName = 'white'): ThemeInfo {
	return { name: theme, styles: allThemeStyles[theme] };
}

export interface ThemeInfo {
	name: ThemeName;
	styles: ThemeStyles;
}

const allThemesRawStyles = {
	...fullRawStyles,
	...globalRawStyles,
	...lightRawStyles,
	...lightnessRawStyles,
	...supportRawStyles,
	...careerRawStyles,
	...conversionRawStyles,
};

export const allThemeStyles = allThemesRawStyles as unknown as Record<ThemeName, ThemeStyles>;
export const allThemeNameArray = Object.keys(allThemesRawStyles);
export type ThemeName = keyof typeof allThemesRawStyles;

export const fullThemeNameArray = Object.keys(fullRawStyles);
export type FullThemeName = keyof typeof fullRawStyles;

export const globalThemeNameArray = Object.keys(globalRawStyles);
export type GlobalThemeName = keyof typeof globalRawStyles;

export const lightThemeNameArray = Object.keys(lightRawStyles);
export type LightThemeName = keyof typeof lightRawStyles;

export const lightnessThemeNameArray = Object.keys(lightnessRawStyles);
export type LightnessThemeName = keyof typeof lightnessRawStyles;

export const supportThemeNameArray = Object.keys(supportRawStyles);
export type SupportThemeName = keyof typeof supportRawStyles;

export const careerThemeNameArray = Object.keys({ ...careerRawStyles, ...fullRawStyles.white });
export type CareerThemeName = keyof typeof supportRawStyles;

export const conversionThemeNameArray = Object.keys({ ...conversionRawStyles, ...fullRawStyles.white });
export type ConversionThemeName = keyof typeof supportRawStyles;

export interface ThemeStyles {
	bgColor?: string;
	titleColor?: string;
	titlePartTwoColor?: string;
	smallTitleColor?: string;
	textColor?: string;
	accentColor?: string;
	accentTextColor?: string;
	iconColor?: string;
	antiIconColor?: string;
	brandlineColor?: string;
	buttonFilled?: string;
	buttonOutline?: string;
	textLink?: string;
	backgroundPattern?: string;
	card?: string;
	breadcrumb?: string;
	careerButton?: string;
	muted?: string;
	row?: string;
	column?: string;
	borderColor?: string;
	linkToggleFilled?: string;
}

export const getChildTheme = (theme: ThemeName) => {
	switch (theme) {
		case 'green':
			return 'lightMintGreen';
		case 'yellow':
			return 'lightYellow';
		case 'purple':
			return 'lightPurple';
		case 'beige':
			return 'lightBeige';
		case 'darkGreen':
			return 'mintGreen';
		case 'primary':
			return 'lightYellow';
		case 'secondary':
			return 'mintGreen';
		case 'lightGreen':
			return 'lightGreen';
		default:
			return 'white';
	}
};

export function getCorrespondingLegacyTheme(theme: Themes['theme']) {
	if (theme && allThemeNameArray.includes(theme)) return theme as ThemeV2Storyblok['theme'];
	switch (theme) {
		case 'beige-medium':
			return 'beige';
		case 'beige-10':
		case 'beige-25':
		case 'beige-light':
			return 'lightBeige';
		case 'dark-green':
			return 'darkGreen';
		case 'light-grey':
		case 'light-green':
			return 'lightGreen';
		case 'mint-green':
			return 'mintGreen';
		case 'back-yellow':
		case 'strong-yellow':
			return 'yellow';
		case 'pink':
			return 'support';
		default:
			return 'white';
	}
}
