import { Document } from 'storyblok-rich-text-react-renderer';
import { t } from 'ttag';

/**
 * Truncate a string to a certain length, but maintain whole words and rather truncate
 * a bit more than splitting a word
 */
export const truncateButWholeWords = (text: string, length = 160): string => {
	if (!text) {
		return '';
	}
	const spaceIndexes: number[] = text
		.split('')
		.map<[string, number]>((c, i) => [c, i])
		.filter(([c]) => c === ' ')
		.map<number>(([, i]) => i)
		.reverse();
	const limit = spaceIndexes.find((i) => i <= length) || length;
	return text.slice(0, limit);
};

export const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const capitalizeEachWord = (string: string) => {
	return string
		.trim()
		.split(/ +/)
		.map((word) => capitalizeFirstLetter(word))
		.join(' ');
};

/**
 * Removes unwanted characters and returns a safe string
 */
export const sanitize = (string: string, replacement: string): string => {
	return string.replace(/[^a-z0-9./-]+/gi, replacement);
};

export const isSlugClean = (string: string): boolean => {
	return /^[a-z0-9/-]+$/i.test(string);
};

export const isRichtextEmpty = (text: Document): boolean => {
	return !text?.content?.[0].content || text?.content?.[0].content?.length < 1;
};

/**
 * Delay calculating some constant thing until the first time it is used.
 *
 * For example useful to avoid reading process.env variables in the top level
 * context which forces them to be available on docker build.
 *
 * Instead do like these examples:
 *
 * ```typescript
 * const siteFolder = memoizeString(() => {
 *   const siteFolder = process.env.SITE_FOLDER;
 *   if (!siteFolder) throw new Error('Missing SITE_FOLDER');
 *   return siteFolder
 * })
 * ```
 *
 * ```typescript
 * const gatewayUrl = memoizeString(() => {
 *   const host = process.env.STORYBLOK_GATEWAY;
 *   if (!host) throw new Error('Missing STORYBLOK_GATEWAY');
 *   return `http://${host}/internalapi/storyblok-gateway/content-v4/multisite/cdn/`;
 * })
 * ```
 */

export function memoizeString(callback: () => string): { valueOf: () => string; toString: () => string } {
	let value: string | undefined;

	function getValue() {
		if (value === undefined) value = callback();
		return value;
	}

	return { valueOf: getValue, toString: getValue };
}

const getMonth = (number: number) => {
	switch (number) {
		case 0:
			return t`januari`;
		case 1:
			return t`februari`;
		case 2:
			return t`mars`;
		case 3:
			return t`april`;
		case 4:
			return t`maj`;
		case 5:
			return t`juni`;
		case 6:
			return t`juli`;
		case 7:
			return t`augusti`;
		case 8:
			return t`september`;
		case 9:
			return t`oktober`;
		case 10:
			return t`november`;
		case 11:
			return t`december`;
		default:
			return '';
	}
};

export const useDateString = (dateString: string | undefined) => {
	if (!dateString) {
		return null;
	}
	const date = new Date(dateString);
	const day = date.getDate();
	const month = date.getMonth();
	const year = date.getFullYear();

	return `${day} ${getMonth(month)} ${year}`;
};
