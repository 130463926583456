import { BackgroundImage, BrandLine, ContainerFluid, Heading, Section, Slider, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { renderRichText } from '@/bloks/RichText';
import { AssetStoryblok, HeaderLoanSliderStoryblok, RichtextStoryblok } from '@/components';
import { useServerPageConfig } from '@/contexts/config';
import { editableBlok } from '@/utils/storyblok';
import { useEffect, useState } from 'react';
import { t } from 'ttag';
import styles from './HeaderLoanSlider.module.scss';

interface BlokProps {
	blok: HeaderLoanSliderStoryblok;
}

interface Props {
	title: string;
	shortText?: RichtextStoryblok;
	backgroundImage: AssetStoryblok;
	loanTitle: string;
	linkButton: LinkButtonProps[];
	_editable?: string;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	title: blok.title,
	shortText: blok.shortText,
	backgroundImage: blok.backgroundImage,
	loanTitle: blok.loanTitle,
	linkButton: blok.linkButton.map((btn) => LinkButton.blokProps({ blok: btn })),
	_editable: blok._editable,
});

interface CompanyLoan {
	amountSEK: number;
	nrOfMonths: number;
}

export const HeaderLoanSlider: Blok<Props, BlokProps> = ({
	title,
	shortText,
	backgroundImage,
	loanTitle,
	linkButton,
	_editable,
}) => {
	const LOAN_MIN = 10000;
	const LOAN_MAX = 2000000;
	const MONTHS_MIN = 6;
	const MONTHS_MAX = 48;

	const [amount, setAmount] = useState<number>(LOAN_MIN);
	const [months, setMonths] = useState<number>(MONTHS_MIN);

	const { locale } = useServerPageConfig();

	useEffect(() => {
		const companyLoanData = localStorage.getItem('companyLoan');
		if (!companyLoanData) {
			return;
		}
		const companyLoan: CompanyLoan = JSON.parse(companyLoanData);
		setAmount(companyLoan.amountSEK);
		setMonths(companyLoan.nrOfMonths);
	}, []);

	const calculateSteps = (array: { step: number; increment?: number }[]) => {
		let i = 0;
		let val = array[0].step;
		let out: number[] = [array[0].step];
		while (i < array.length) {
			const step = array[i];
			const nextStep = array[i + 1];
			if (!nextStep) break;
			if (step.step > nextStep.step) break;
			if (!step.increment) break;

			val += step.increment;
			out = [...out, val];

			if (val === nextStep.step) {
				i++;
			}
		}
		return out;
	};

	const loanSekSteps = calculateSteps([
		{ step: LOAN_MIN, increment: 5000 },
		{ step: 100000, increment: 25000 },
		{ step: 200000, increment: 50000 },
		{ step: 500000, increment: 100000 },
		{ step: 1000000, increment: 250000 },
		{ step: LOAN_MAX },
	]);
	const loanMonthSteps = calculateSteps([
		{ step: MONTHS_MIN, increment: 6 },
		{ step: 12, increment: 6 },
		{ step: 18, increment: 6 },
		{ step: 24, increment: 12 },
		{ step: 36, increment: 12 },
		{ step: MONTHS_MAX },
	]);

	return (
		backgroundImage && (
			<ContainerFluid {...editableBlok({ _editable })} theme="darkGreen">
				<div className={styles.container}>
					<BackgroundImage
						asset={backgroundImage}
						priority
						zIndex={1}
						className={styles.background}
						isHeader
						overlay
						overlayDirection="center"
					/>

					<Section
						paddingX={{ base: 'xl', lg: 'none' }}
						paddingY={{ base: 'none' }}
						as="header"
						className={styles.content}
					>
						<div className={styles.titleContainer}>
							{title && (
								<>
									<BrandLine marginBottom="xl" />
									<Heading as="h1" marginBottom="xl" title={title} />
								</>
							)}
							{shortText && (
								<div className={styles.shortText}>{renderRichText(shortText, { textMarginBottom: 'none' })}</div>
							)}
						</div>
						<div className={styles.loanContainer}>
							{loanTitle && (
								<Heading as="div" size="h4" title={loanTitle} marginBottom="none" className={styles.loanText} />
							)}
							<div className={styles.sliderContainer}>
								<Slider
									min={0}
									max={loanSekSteps.length - 1}
									onInput={(e) => setAmount(loanSekSteps[Number(e.currentTarget.value)])}
									label={t`Belopp`}
									valueLabel={
										locale === 'sv'
											? `${Intl.NumberFormat('sv-SE', { notation: 'standard' }).format(amount)} ${t`kr`}`
											: `${t`SEK`} ${Intl.NumberFormat('sv-SE', { notation: 'standard' }).format(amount)}`
									}
									labelClassName={styles.loanText}
									defaultValue={loanSekSteps.findIndex((v) => v === amount)}
								/>
								<Slider
									min={0}
									max={loanMonthSteps.length - 1}
									onInput={(e) => setMonths(loanMonthSteps[Number(e.currentTarget.value)])}
									label={t`Låneperiod`}
									valueLabel={`${months} ${t`månader`}`}
									labelClassName={styles.loanText}
									defaultValue={loanMonthSteps.findIndex((v) => v === months)}
								/>
							</div>
							<Text marginBottom="none" className={styles.loanText}>{t`Powered by Capcito - En del av Fortnox`}</Text>
						</div>
						{linkButton &&
							linkButton.map((btn, index) => (
								<div key={index}>
									<div className={styles.desktop}>
										<LinkButton
											variant="filled"
											{...btn}
											onClick={() => {
												localStorage.setItem('companyLoan', JSON.stringify({ amountSEK: amount, nrOfMonths: months }));
											}}
										/>
									</div>
									<div className={styles.mobile}>
										<LinkButton
											variant="outlined"
											{...btn}
											onClick={() => {
												localStorage.setItem('companyLoan', JSON.stringify({ amountSEK: amount, nrOfMonths: months }));
											}}
											applyLightnessTheme
										/>
									</div>
								</div>
							))}
					</Section>
				</div>
			</ContainerFluid>
		)
	);
};

HeaderLoanSlider.blokProps = blokProps;
