interface Rating {
	averageScore: number;
	numberOfRatings: number;
}

/**
 * @deprecate This category interface should be deprecated but needs changes in api.
 */
export interface Category {
	categoryNameSv: string;
	categoryNameEn: string;
	categoryId: number;
}

export interface IntegrationContactInformation {
	companyName: string;
	phone?: string;
	email?: string;
}

export enum ImageType {
	ICON = 'ICON',
	HERO = 'HERO',
	LOGO = 'LOGO',
}
interface Image {
	path: string;
	type: ImageType;
}

export interface Scope {
	scopeId: string;
	scopeNameSv: string;
	scopeNameEn: string;
	tooltipText: string;
	active: boolean;
	external: boolean;
}
export enum IntegrationClientType {
	BuyableApp = 'BuyableApp',
	InternalApp = 'InternalApp',
}
interface PriceModel {
	clientType: IntegrationClientType;
	price: string;
}

interface UniqueSellingPoint {
	title: string;
	points: string[];
}

export interface Insights {
	numberOfUsersRange: string;
}
export interface CustomerReview {
	companyName: string;
	review: string;
	role: string;
}
export interface Integration {
	error?: string;
	appId: string;
	tenantId: string;
	redirectUri: string;
	appName: string;
	summaryTitle: string;
	summary: string;
	about: string;
	howTo: string;
	readMoreUrl: string;
	priceModel: PriceModel;
	urlSlug: string;
	images: Image[];
	scopes: Scope[] | null;
	categories: Category[];
	ratings: Rating;
	contactInformation: IntegrationContactInformation;
	uniqueSellingPoints: UniqueSellingPoint;
	customerReviews: CustomerReview[];
	insights: Insights;
	tags: string[];
}
export interface IntegrationTransform {
	appId: string;
	appName: string;
	summaryTitle: string;
	summary: string;
	about: string;
	readMoreUrl: string;
	urlSlug: string;
	images: Image[];
	contactInformation: IntegrationContactInformation;
	priceModel: PriceModel;
	categories: Category[];
	ratings: Rating;
	insights: Insights;
	tags: string[];
}
export interface IntegrationCategory {
	categoryId: number;
	name: string;
	description: string;
	summary: string;
}
export interface Integrator {
	tenantId: string;
	companyName: string;
	about: string;
	successStory: string;
	contactEmail: string;
	contactPhone: string;
	contactHomepage: string;
	companyLogoKey: string;
	companyLogoUrl: string;
	companyNameSlug: string;
}

export interface IntegrationHit {
	id: string;
	url: string;
	title: string;
	images: AddsearchImage;
	custom_fields: {
		averageRating: number;
		numberOfUsersRange: string;
		numberOfRatings: number;
		priceModel: string;
		tags?: string[];
		category?: string;
		appName?: string;
		companyName?: string;
	};
	meta_description: string | null;
	categories: string[];
}
interface AddsearchImage {
	main: string;
	main_b64: string;
	capture: string;
}
