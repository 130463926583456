import { Carousel, Grid, ShowMoreButton } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { CardMediumGroupStoryblok } from '@/components';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ThemeProvider } from '@/contexts/theme';
import { getCorrespondingLegacyTheme } from '@/themes';
import { Themes } from '@/types';
import { cls } from '@/utils';
import { isOdd } from '@/utils/isOdd';
import { editableBlok } from '@/utils/storyblok';
import { useState } from 'react';
import { t } from 'ttag';
import { CardMedium, CardMediumProps } from '../CardMedium/CardMedium';
import styles from './CardMediumGroup.module.scss';
import { useCardMediumGridColumns } from './useCardMediumGridColumns';

interface BlokProps {
	blok: CardMediumGroupStoryblok;
	meta?: {
		nrOfCards?: number;
		noBackground?: boolean;
		theme?: Themes['theme'];
	};
}

const blokProps = ({ blok, meta }: BlokProps): CardMediumGroupProps => ({
	cards: blok.cards?.map((card) =>
		CardMedium.blokProps({
			blok: card,
			meta,
		}),
	),
	theme: meta?.theme,
	titleSize: blok.titleSize,
	cardButtonVariant: blok.cardButtonVariant,
	groupGrid: blok.groupGrid,
	groupLink: blok.groupLink?.[0] && LinkButton.blokProps({ blok: blok.groupLink[0] }),
	_editable: blok._editable,
});

export interface CardMediumGroupProps {
	cards: CardMediumProps[];
	theme?: Themes['theme'];
	titleSize?: 'h3' | 'h4' | 'h5';
	cardButtonVariant?: 'filled' | 'text';
	groupGrid?: boolean;
	groupLink?: LinkButtonProps;
	_editable?: string;
}

export const CardMediumGroup: Blok<CardMediumGroupProps, BlokProps> = ({
	cards,
	theme,
	titleSize = 'h5',
	cardButtonVariant = 'filled',
	groupGrid = false,
	groupLink,
	_editable,
}) => {
	const { setTargetInteraction } = useImpressionTracking();
	const nrOfCards = cards?.length ?? 0;
	const addGroupedGrid = groupGrid && nrOfCards !== 5 && nrOfCards !== 3;
	const columns = useCardMediumGridColumns(nrOfCards, addGroupedGrid);
	const cardsPerRow = nrOfCards === 5 ? 3 : 4;
	const nrOfRows = nrOfCards / cardsPerRow;
	const [showRows, setShowRows] = useState(6);
	const smallGap = nrOfCards === 4 && !addGroupedGrid;
	const showCarousel = nrOfCards > 4;
	const indicatorColor = theme === 'white' ? 'grey' : 'white';
	const groupLinkButtonVariant = cardButtonVariant === 'text' ? 'filled' : 'text';

	const onShowMore = () => {
		setShowRows(showRows < nrOfRows ? showRows + 1 : showRows);
	};

	return (
		<div
			className={cls(
				showCarousel && styles.showCarousel,
				(addGroupedGrid || nrOfCards === 2) && styles.center,
				addGroupedGrid ? styles.groupedGrid : styles.unGroupedGrid,
				isOdd(nrOfCards) && styles.oddNrOfCards,
				styles[`nr-of-grid-items--${nrOfCards}`],
			)}
		>
			<Grid
				columns={columns}
				{...editableBlok({ _editable })}
				className={cls(styles.grid, smallGap ? styles.smallGap : styles.gap)}
			>
				{cards?.slice(0, cardsPerRow * showRows)?.map((card) => (
					<Grid.Item key={card._uid} className={styles.gridItem}>
						<CardMedium {...card} nrOfCards={nrOfCards} buttonVariant={cardButtonVariant} titleSize={titleSize} />
					</Grid.Item>
				))}
			</Grid>

			{showCarousel && (
				<Carousel className={styles.carousel} showIndicators={true} indicatorColor={indicatorColor}>
					{cards.map((card, index) => {
						return (
							<div key={card._uid} className={styles.card}>
								<CardMedium
									data-index={index}
									{...card}
									nrOfCards={nrOfCards}
									buttonVariant={cardButtonVariant}
									titleSize={titleSize}
								/>
							</div>
						);
					})}
				</Carousel>
			)}

			{nrOfCards > 6 && showRows < nrOfRows && (
				<ThemeProvider theme={getCorrespondingLegacyTheme(theme)}>
					<ShowMoreButton onClick={onShowMore}>{t`Visa fler`}</ShowMoreButton>
				</ThemeProvider>
			)}

			{groupLink && (
				<ThemeProvider theme={getCorrespondingLegacyTheme(theme)}>
					<div className={styles.btnPosition}>
						<LinkButton
							{...groupLink}
							variant={groupLinkButtonVariant}
							onClick={() => {
								setTargetInteraction({ contentTarget: 'default' });
							}}
						/>
					</div>
				</ThemeProvider>
			)}
		</div>
	);
};
CardMediumGroup.blokProps = blokProps;
