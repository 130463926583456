import { Grid, Section } from '@/atoms';
import { SectionTextImageStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { editableBlok } from '@/utils/storyblok';
import { renderRichText } from '../../RichText';

export const renderImage = (image: any): JSX.Element => {
	return <>{image?.id && <ImageAsset absolute key={image.id} asset={image} filters="smart" />}</>;
};

interface Props {
	blok: SectionTextImageStoryblok;
	meta?: {
		exo2?: boolean;
	};
}

/**
 * Section component with Textblok and Image 50/50 grid
 */
export const SectionTextImage: React.FC<Props> = ({ blok, meta }) => {
	const { text, image, imagePlacement } = blok;

	return (
		<Section {...editableBlok(blok)} padding={{ base: 'none' }}>
			{imagePlacement === 'right' ? (
				<Grid columns={{ base: 1, md: 2, lg: 2, xl: 2 }} colGap={'3xl'} rowGap={'md'}>
					<Grid.Item>{renderRichText(text, { exo2: meta?.exo2 })}</Grid.Item>
					<Grid.Item>{renderImage(image)}</Grid.Item>
				</Grid>
			) : (
				<Grid columns={{ base: 1, md: 2, lg: 2, xl: 2 }} colGap={'3xl'} rowGap={'md'}>
					<Grid.Item>{renderImage(image)}</Grid.Item>
					<Grid.Item>{renderRichText(text, { exo2: meta?.exo2 })}</Grid.Item>
				</Grid>
			)}
		</Section>
	);
};
