import { Heading } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton } from '@/bloks/Button';
import { List } from '@/bloks/Other';
import { renderRichText } from '@/bloks/RichText';
import { AssetStoryblok, CardTwoStoryblok, LinkButtonStoryblok, ListStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './CardTwo.module.scss';

interface CardTwoMetaProps {
	titleSize?: 'h4' | 'h5';
	buttonVariant?: 'text' | 'filled';
	nrOfCards?: number;
	placing?: number;
}

interface BlokProps {
	blok: CardTwoStoryblok;
	meta?: CardTwoMetaProps;
}

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	smallTitle: blok.smallTitle,
	title: blok.title,
	text: blok.text,
	linkButtons: blok?.linkButtons,
	image: blok.image,
	list: blok.list,
	titleSize: meta?.titleSize,
	buttonVariant: meta?.buttonVariant,
	nrOfCards: meta?.nrOfCards,
	placing: meta?.placing,
	_editable: blok._editable,
});

interface Props {
	smallTitle?: string;
	title?: string;
	text?: any;
	linkButtons?: LinkButtonStoryblok[];
	_editable?: string;
	className?: string;

	/**
	 * This props will be removed when CardTwo converts to SideBarCard
	 */
	image?: AssetStoryblok;
	list?: ListStoryblok[];
	titleSize?: 'h4' | 'h5';
	buttonVariant?: 'text' | 'filled';
	nrOfCards?: number;
	placing?: number;
}

/**
 * @deprecated CardTwo will be converted to SideBarCard
 */
export const CardTwo: Blok<Props, BlokProps> = ({
	smallTitle,
	title,
	text,
	linkButtons,
	_editable,
	className,
	image,
	list,
	titleSize = 'h4',
	buttonVariant = 'filled',
	nrOfCards = 2,
	placing,
}) => {
	return (
		<article {...editableBlok({ _editable })} className={cls(styles.container, className)}>
			{image?.filename && (
				<div className={cls(styles.image, styles[`card-height-${nrOfCards}`])}>
					<ImageAsset absolute asset={image} objectFit="cover" fill objectPosition="center center" />
				</div>
			)}
			<div className={styles.textContainer}>
				{smallTitle && (
					<Heading
						as="div"
						size="h6"
						title={smallTitle}
						className={styles.smallTitle}
						data-testid="card-two-header-h6"
						marginBottom="sm"
					/>
				)}
				{title && <Heading as={titleSize} title={title} data-testid="knowledge-card-header" />}
				{text && (
					<div data-testid="card-two-content" className={styles.description}>
						{renderRichText(text)}
					</div>
				)}
				{list?.[0] && (
					<div className={styles.listContainer}>
						<List className={styles.uspList} {...List.blokProps({ blok: list[0] })} iconColor="--primary-green" />
					</div>
				)}
				<footer className={styles.footer}>
					{linkButtons?.map((button) => (
						<LinkButton key={button._uid} {...LinkButton.blokProps({ blok: button })} variant={buttonVariant} />
					))}
				</footer>
			</div>
		</article>
	);
};

CardTwo.blokProps = blokProps;
