import { Icon } from '@/atoms';
import { getTheme } from '@/bloks/Page/NavigationPageEducation/CardEducation/EducationTypeTheme';
import { Item, ItemFilter, filterAnyMatch } from '@/bloks/Page/NavigationPageEducation/EducationFilter/ItemFilter';
import { CardEducationStoryblok } from '@/components';
import { CustomFields, EducationType } from '@/contexts/eduadmin';
import React, { useEffect, useRef, useState } from 'react';
import cls from 'classnames';
import { t } from 'ttag';
import styles from './FilterContainer.module.scss';

interface Props {
	educationCards: CardEducationStoryblok[];
	subjects?: CustomFields | null;
	targetGroups?: CustomFields | null;
	educationTypes?: CustomFields | null;
	setFilteredCards: (courses: CardEducationStoryblok[]) => void;
}

const getDefinedFields = (customFields: CustomFields | null | undefined) => {
	return (
		(customFields?.value.flatMap((field) =>
			field.CustomFieldAlternatives.flatMap((value) => value.CustomFieldAlternativeValue),
		) as string[]) || []
	);
};

export const FilterContainer: React.FC<Props> = ({
	educationCards,
	subjects,
	targetGroups,
	educationTypes,
	setFilteredCards,
}) => {
	const defaultFilter: Item[] = [];
	const [showFilters, setShowFilters] = useState(false);
	const [selectedEducationType, setSelectedEducationType] = useState(defaultFilter);
	const [selectedSubjects, setSelectedSubjects] = useState(defaultFilter);
	const [selectedTargetGroups, setSelectedTargetGroups] = useState(defaultFilter);
	const [selectedPrice, setSelectedPrice] = useState(defaultFilter);

	const educationCardsRef = useRef(educationCards);
	const actualSubjects = educationCardsRef.current?.flatMap((card) => card.subjects) || [];
	const actualTargetGroups = educationCardsRef.current?.flatMap((card) => card.targetGroups) || [];

	useEffect(() => {
		const filteredCards = educationCardsRef.current.filter((card) => {
			return (
				filterAnyMatch(selectedEducationType, card.educationType || '') &&
				filterAnyMatch(selectedSubjects, (card.subjects || []).join(' ')) &&
				filterAnyMatch(selectedTargetGroups, (card.targetGroups || []).join(' ')) &&
				filterAnyMatch(selectedPrice, card.price === '0' ? 'Kostnadsfri' : '-')
			);
		});
		setFilteredCards(filteredCards);
	}, [selectedEducationType, selectedSubjects, selectedTargetGroups, selectedPrice, setFilteredCards]);

	return (
		<div className={styles.filterContainer}>
			<div onClick={() => setShowFilters(!showFilters)} className={styles.filterHeader}>
				{t`Filtrera utbildningar`}
				<Icon name={showFilters ? 'fa-close' : 'fa-filter'} />
			</div>

			<div className={cls(styles.filterContent, { [styles.show]: showFilters })}>
				<ItemFilter
					items={getDefinedFields(subjects)
						.filter((value) => actualSubjects.includes(value))
						.map((name) => ({
							name: name,
							themeInfo: undefined,
						}))}
					selectedFilters={selectedSubjects}
					setSelectedFilters={setSelectedSubjects}
					label={t`Ämne`}
				/>
				<ItemFilter
					items={getDefinedFields(educationTypes).map((name) => ({
						name: name,
						themeInfo: getTheme(name as EducationType),
					}))}
					selectedFilters={selectedEducationType}
					setSelectedFilters={setSelectedEducationType}
					label={t`Utbildningstyp`}
				/>
				<ItemFilter
					items={getDefinedFields(targetGroups)
						.filter((value) => actualTargetGroups.includes(value))
						.map((name) => ({
							name: name,
							themeInfo: undefined,
						}))}
					selectedFilters={selectedTargetGroups}
					setSelectedFilters={setSelectedTargetGroups}
					label={t`Målgrupp`}
				/>
				<ItemFilter
					items={[{ name: 'Kostnadsfri', themeInfo: undefined }]}
					selectedFilters={selectedPrice}
					setSelectedFilters={setSelectedPrice}
					label={t`Kostnad`}
				/>
			</div>
		</div>
	);
};
