import { LinkButton } from '@/bloks/Button';
import { t } from 'ttag';
import styles from './ShortCuts.module.scss';

interface Props {
	className?: string;
}
export const ShortCuts: React.FC<Props> = ({ className }) => {
	const quickChoices: Record<string, any>[] = [
		{
			title: t`Gå till kategorier`,
			icon: 'tags',
			href: '/integrationer/kategorier',
		},
		{
			title: t`Gå till leverantörer`,
			icon: 'handshake',
			href: '/integrationer/leverantorer',
		},
	];

	return (
		<>
			<div className={styles.mutedTextSection}>
				<span className={styles.mutedText}>{t`eller`}</span>
			</div>

			<div className={styles.shortCuts}>
				{quickChoices.map((cat) => {
					return (
						<LinkButton key={cat.title} href={cat.href}>
							{cat.title}
						</LinkButton>
					);
				})}
			</div>
		</>
	);
};
