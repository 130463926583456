import { Carousel, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import DynamicComponent from '@/bloks/DynamicComponent';
import { renderRichText } from '@/bloks/RichText';
import { PriceCardGroupV2Storyblok } from '@/components';
import { useWindowSize } from '@/hooks/useWindowSize';
import { ScrollTrigger } from '@/utils/gsap';
import { editableBlok } from '@/utils/storyblok';
import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import styles from './PriceCardGroupV2.module.scss';

interface BlokProps {
	blok: PriceCardGroupV2Storyblok;
	applyLightnessTheme?: boolean;
	containerFoldoutButton?: JSX.Element;

	meta?: {
		zone?: number;
	};
}

export const PriceCardGroupV2 = ({ blok, meta, applyLightnessTheme = false, containerFoldoutButton }: BlokProps) => {
	const { priceCard, linkButton, primary } = blok;
	const [showMore, setShowMore] = useState(false);
	const windowSize = useWindowSize();
	const isThreeCards = blok.priceCard?.length === 3;
	const isFourCards = blok.priceCard?.length === 4;
	const isOneCard = blok.priceCard?.length === 1;
	const defaultSelectedCard = 2;

	const getSlideWidth = () => {
		const smallCardSlideWidth = 287;
		const largeCardSlideWidth = 364;

		if (windowSize.width && windowSize.width < 400) {
			return smallCardSlideWidth;
		}
		return largeCardSlideWidth;
	};

	const getSlidePercentage = () => {
		return windowSize.width && (getSlideWidth() / windowSize.width) * 100;
	};

	const getPrimaryCard = () => {
		const defaultPrimaryCard = 1;

		if (!primary) {
			return defaultPrimaryCard;
		}
		return parseInt(primary);
	};

	const getPriceCards = () => {
		return priceCard?.map((card, index) => {
			const currentCard = index + 1;
			const primaryCard = getPrimaryCard();
			const isPrimaryCard = primaryCard == currentCard;
			return (
				<div
					key={card._uid}
					className={cls(
						styles.card,
						isThreeCards && styles.threeCards,
						isFourCards && styles.fourCards,
						isOneCard && styles.oneCard,
					)}
				>
					<DynamicComponent
						key={card._uid}
						blok={card}
						meta={{ primary: isPrimaryCard, showMore, toggleShowMore, priceCardV2: true, zone: meta?.zone }}
					/>
				</div>
			);
		});
	};

	const toggleShowMore = () => {
		setShowMore(!showMore);
	};

	useEffect(() => {
		ScrollTrigger.refresh();
	}, [showMore]);

	return (
		<div {...editableBlok(blok)}>
			{priceCard && !isOneCard && (
				<Carousel
					className={cls(
						styles.carousel,
						isThreeCards && styles.threeCardsCarousel,
						isFourCards && styles.fourCardsCarousel,
					)}
					selectedIndex={defaultSelectedCard}
					centerMode={true}
					showIndicators={true}
					centerSlidePercentage={getSlidePercentage()}
				>
					{getPriceCards()}
				</Carousel>
			)}
			<div
				className={cls(
					styles.container,
					isThreeCards && styles.threeCardsContainer,
					isFourCards && styles.fourCardsContainer,
					isOneCard && styles.oneCardContainer,
				)}
			>
				{getPriceCards()}
			</div>
			{blok?.additionalText && (
				<Text
					applyLightnessTheme={applyLightnessTheme}
					as="div"
					className={cls(styles.additionalText, linkButton && linkButton?.length > 0 && styles.adjustMargin)}
					marginBottom="custom"
				>
					{typeof blok?.additionalText === 'string' ? blok?.additionalText : renderRichText(blok?.additionalText)}
				</Text>
			)}
			{linkButton && linkButton?.length > 0 && (
				<div className={cls(styles.linkButtonContainer, containerFoldoutButton && styles.adjustMargin)}>
					<LinkButton
						key={linkButton[0]._uid}
						{...LinkButton.blokProps({ blok: linkButton[0] })}
						variant="outlined"
						applyLightnessTheme={applyLightnessTheme}
					/>
				</div>
			)}
		</div>
	);
};
