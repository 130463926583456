import { Icon, IconButton, Text } from '@/atoms';
import { cls } from '@/utils';
import React, { Fragment, useState } from 'react';
import { t } from 'ttag';
import { Availability } from './Availability';
import styles from './SectionComparisonTable.module.scss';

export interface SegmentDescriptionsProps {
	description?: string;
	availability?: string[];
	_uid: string;
	_editable?: string;
}

interface Props {
	isLastRow: boolean;
	isLastRowOfLastSegment: boolean;
	row: any;
	primary?: string;
	secondaryChildThemeInfo: any;
	childThemeInfo: any;
	theme: string;
	descriptions: SegmentDescriptionsProps[];
	tableSlug: string;
	isStickyEnd?: boolean;
}

export const SegmentRow: React.FC<Props> = ({
	isLastRow,
	isLastRowOfLastSegment,
	row,
	primary,
	childThemeInfo,
	secondaryChildThemeInfo,
	descriptions,
	theme,
	tableSlug,
	isStickyEnd,
}) => {
	const [isExtended, setIsExtended] = useState(false);
	const hasDescription = descriptions
		?.filter((segmentDescription: any) => segmentDescription.full_slug.includes(tableSlug))
		.some((desc: any) => desc.name === row.name);

	if (!descriptions) return null;

	const renderDescription = (segmentDescription: any, index: number) => {
		if (segmentDescription.full_slug.includes(tableSlug) && segmentDescription.name === row.name && isExtended) {
			return (
				<div key={index} className={styles.descriptionContainer}>
					<div onClick={() => setIsExtended(!isExtended)}>
						<Text size="small">{segmentDescription.content.description}</Text>
					</div>
					<Text size="small">{t`Tillgänglighet`}:</Text>
					<Availability availableOn={segmentDescription.content.availability} />
				</div>
			);
		}
		return null;
	};

	return (
		<Fragment>
			<div className={cls(styles.row, styles.hideOnDesktop, styles.featureName)}>
				<div className={cls(styles.col)}>
					<div
						className={cls(
							styles.content,
							!isLastRow && styles.borderBottom,
							hasDescription && styles.cursorPointer,
							styles.iconAndSegment,
						)}
						onClick={() => setIsExtended(!isExtended)}
					>
						{hasDescription && (
							<IconButton
								iconName={isExtended ? 'minus' : 'plus'}
								aria-label={'Mer information'}
								iconColor="--text-dark"
							/>
						)}
						{row?.name}
					</div>

					<div>{hasDescription && descriptions.map(renderDescription)}</div>
				</div>
			</div>
			<div className={cls(styles.row, theme != 'primary' && styles.stickyRowNeutral)}>
				<div className={cls(styles.col, styles.hideOnMobile)}>
					<div className={cls(!isLastRow && styles.borderBottom)}>
						<div
							className={cls(styles.content, styles.iconAndSegment, hasDescription && styles.cursorPointer)}
							onClick={() => setIsExtended(!isExtended)}
						>
							{row?.name}
							{hasDescription && (
								<IconButton
									iconName={isExtended ? 'minus' : 'plus'}
									aria-label={'Mer information'}
									iconColor="--text-dark"
								/>
							)}
						</div>

						{hasDescription && descriptions.map(renderDescription)}
					</div>
				</div>
				{row?.columns?.map((col: string, index: number) => {
					const segmentStyles = {
						primarySegment: index.toString() === primary,
						lastRowOfLastSegment: isLastRowOfLastSegment,
					};

					const columnStyles = {
						primaryThemeColumn: theme === 'primary' && index.toString() === primary,
						firstColumn: index === 0 && index.toString() !== primary,
						thirdColumn: index === 2 && index.toString() !== primary,
					};
					const themeBorderStyle =
						index.toString() === primary
							? childThemeInfo.styles.borderColor
							: secondaryChildThemeInfo.styles.borderColor;
					return (
						<div
							key={index}
							className={cls(
								styles.col,
								theme !== 'primary' && !primary && styles.segmentContentWhiteBorder,
								segmentStyles.primarySegment
									? columnStyles.primaryThemeColumn
										? childThemeInfo.styles.column
										: childThemeInfo.styles.bgColor
									: secondaryChildThemeInfo.styles.bgColor,
								segmentStyles.primarySegment &&
									segmentStyles.lastRowOfLastSegment &&
									!isStickyEnd &&
									styles.primaryRowMargin,
								columnStyles.firstColumn && segmentStyles.lastRowOfLastSegment && styles.borderBottomLeftRadius,
								columnStyles.thirdColumn && segmentStyles.lastRowOfLastSegment && styles.borderBottomRightRadius,
								theme === 'primary' && !segmentStyles.primarySegment && styles.secondaryRowColorSmallScreen,
								theme === 'primary' && segmentStyles.primarySegment && styles.primaryRowColorSmallScreen,
							)}
						>
							<div
								className={cls(
									styles.content,
									styles.segmentRowContentPadding,
									!isLastRow && styles.borderBottom,
									!themeBorderStyle && primary == '' && styles.borderBottomWhite,
									!isLastRow && themeBorderStyle,
									styles.textAlignCenter,
									isLastRow && styles.lastRow,
								)}
							>
								{getColumnContent(col)}
							</div>
						</div>
					);
				})}
			</div>
		</Fragment>
	);
};

const getColumnContent = (value: string) => {
	if (value === 'true') {
		return <Icon name="circle-check" color="--primary-green" size="1x" className={styles.icon} type={'solid'} />;
	}
	if (value === 'false') {
		return <Icon name="xmark" color="--accent-orange" size="1x" className={styles.icon} type={'solid'} />;
	}
	return value;
};
