import {
	Button,
	ContainerFluid,
	Datepicker,
	Divider,
	Grid,
	Heading,
	Icon,
	Incrementer,
	Text,
	TextField,
	Tooltip,
} from '@/atoms';
import { ChartData } from '@/bloks/Calculator/ProfitMarginCalculator';
import { EmployeeCalculatorStoryblok } from '@/components';
import { formatMoney } from '@/utils';
import { handleFormSubmitEvent } from '@/utils/piwik';
import { getStoryContent } from '@/utils/storyblok';
import { Chart, registerables } from 'chart.js';
import React, { FormEvent, useEffect, useState } from 'react';
import { t } from 'ttag';
import { CalculationResultRow } from '../CalculationResultRow';
import styles from '../CalculatorStyling.module.scss';
import employeeCalculatorStyles from './EmployeeCalculator.module.scss';
import { GrowthSupport } from './GrowthSupport/GrowthSupport';
import { VacationPicker } from './VacationPicker/VacationPicker';

interface EmployeeCalculationInfo {
	averageMonthlyCost?: number;
	employeeFee?: number;
	salary?: number;
	holidayAllowance?: number;
	insuranceCost?: number;
	specialSalaryTax?: number;
}

interface EmployeeState {
	formattedMonthlyPay: string;
	formattedEstimatedSalary: string;
	date: string;
	vacationDays: number;
	vacationRule: 'Sammalöneregeln' | 'Procentregeln';
	growthSupport: string;
}

interface Props {
	blok: EmployeeCalculatorStoryblok;
}

export const EmployeeCalculator: React.FC<Props> = ({ blok }) => {
	const [employeeState, setEmployeeState] = useState<EmployeeState>({
		formattedMonthlyPay: '',
		formattedEstimatedSalary: '',
		date: '',
		vacationDays: 25,
		vacationRule: 'Sammalöneregeln',
		growthSupport: 'Nej',
	});
	const [employeeCalculationInfo, setEmployeeCalculationInfo] = useState<EmployeeCalculationInfo>({});
	const isMonthlyPayNotEmpty =
		employeeState.vacationRule == 'Sammalöneregeln' && employeeState.formattedMonthlyPay != '';
	const isSalaryAndDateNotEmpty =
		employeeState.vacationRule == 'Procentregeln' &&
		employeeState.formattedEstimatedSalary != '' &&
		employeeState.date != '';

	if (registerables) {
		Chart.register(...registerables);
	}

	useEffect(() => {
		const el = document.getElementById('employeeChart') as HTMLCanvasElement;

		if (!el) {
			return;
		}
		const chartData: Array<ChartData> = [
			{
				label: t` Månadslön (innan skatt)`,
				value: employeeCalculationInfo.salary || 0,
				color: '#FE85B5',
			},
			{
				label: t` Arbetsgivaravgifter`,
				value: employeeCalculationInfo.employeeFee || 0,
				color: '#9C54B9',
			},
			{
				label: t` Semesterlön`,
				value: employeeCalculationInfo.holidayAllowance || 0,
				color: '#FEC400',
			},
			{
				label: t` Försäkringskostnader`,
				value: employeeCalculationInfo.insuranceCost || 0,
				color: '#3785B7',
			},
			{
				label: t` Särskild löneskatt`,
				value: employeeCalculationInfo.specialSalaryTax || 0,
				color: '#D9C1E8',
			},
		];
		chartData.sort((a, b) => b.value - a.value);

		const chart = new Chart(el, {
			type: 'pie',
			data: {
				labels: chartData.map((data) => data.label),
				datasets: [
					{
						data: chartData.map((data) => data.value),
						backgroundColor: chartData.map((data) => data.color),
						hoverOffset: 25,
					},
				],
			},

			options: {
				elements: {
					arc: {
						borderWidth: 0,
					},
				},
				layout: {
					padding: {
						bottom: 25,
						top: 25,
						left: 25,
						right: 25,
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						callbacks: {
							label: (item) => `${item.label}: ${item.formattedValue}kr`,
						},
					},
				},
			},
		});

		return () => {
			chart.destroy();
		};
	}, [employeeCalculationInfo]);

	const resetEmployeeState = ({ formattedEstimatedSalary, formattedMonthlyPay, date }: EmployeeState) => {
		if (formattedEstimatedSalary == '' && formattedMonthlyPay == '' && date == '') {
			setEmployeeCalculationInfo({});
		}
	};

	const getEmployeeFee = (totalSalary: number) => {
		const currentYear = new Date().getFullYear();
		const chosenYear = parseInt(employeeState.date.substring(0, 4));
		const age = currentYear - chosenYear;
		const defaultEmployeeFee = 0.3142;
		const reducedEmployeeFee = 0.1021;

		if (age >= 86) {
			return 0;
		}

		const isInReducedAgeGroup = age <= 18 || (age >= 66 && age <= 85);
		const useGrowthSupport = employeeState.growthSupport == 'Ja' || isInReducedAgeGroup;

		if (useGrowthSupport) {
			return (
				12 *
				(totalSalary > 25000
					? (totalSalary - 25000) * defaultEmployeeFee + 25000 * reducedEmployeeFee
					: totalSalary * reducedEmployeeFee)
			);
		}

		return 12 * totalSalary * defaultEmployeeFee;
	};

	const getHolidayAllowance = (monthlySalary: number, estimatedSalary: number) => {
		if (employeeState.vacationRule == 'Sammalöneregeln') {
			return 0.0043 * monthlySalary * employeeState.vacationDays;
		}
		const extraVacationDays = employeeState.vacationDays > 25 ? employeeState.vacationDays - 25 : 0;
		const holidayAllowancePercent = 0.12 + 0.0048 * extraVacationDays;
		return estimatedSalary * holidayAllowancePercent;
	};

	const getSalary = (monthlySalary: number, estimatedSalary: number) => {
		if (employeeState.vacationRule == 'Sammalöneregeln') {
			return monthlySalary * 12;
		}
		return estimatedSalary;
	};

	const getCalculations = () => {
		const monthlySalary = parseInt(employeeState.formattedMonthlyPay.replace(/\D/g, ''));
		const estimatedSalary = parseInt(employeeState.formattedEstimatedSalary.replace(/\D/g, ''));
		const holidayAllowance = getHolidayAllowance(monthlySalary, estimatedSalary);
		const salary = getSalary(monthlySalary, estimatedSalary);
		const totalSalary = salary + holidayAllowance;
		const employeeFee = getEmployeeFee(totalSalary / 12);
		const insuranceCost = 0.05 * totalSalary;
		const specialSalaryTax = 0.2426 * totalSalary * 0.045;
		const totalInsuranceTax = insuranceCost + specialSalaryTax;
		const totalCost = totalSalary + employeeFee + totalInsuranceTax;
		return [totalCost, employeeFee, salary, holidayAllowance, insuranceCost, specialSalaryTax].map((item) =>
			Math.round(item / 12),
		);
	};

	function handleSubmit(event: FormEvent) {
		event.preventDefault();

		handleFormSubmitEvent({ type: 'calculator', formId: 'employee-calculator' });

		const [averageMonthlyCost, employeeFee, salary, holidayAllowance, insuranceCost, specialSalaryTax] =
			getCalculations();
		setEmployeeCalculationInfo({
			averageMonthlyCost,
			employeeFee,
			salary,
			holidayAllowance,
			insuranceCost,
			specialSalaryTax,
		});
	}

	const calculationText = getStoryContent(blok.calculationText);

	return (
		<ContainerFluid marginBottom="3xl">
			<Grid columns={{ base: 1, lg: 2 }}>
				<Grid.Item className={styles.calculator}>
					<div className={styles.headingRow}>
						<Icon name="calculator" />
						<Heading className={styles.heading} as="div" title={t`Kostnad - anställd `} size="h5" marginBottom="none" />
					</div>
					<form onSubmit={handleSubmit}>
						<div className={employeeCalculatorStyles.datePicker}>
							<div className={styles.textFieldHeading}>
								<Heading title={t`Födelsedatum (anställd)`} as="div" size="h6" />
							</div>
							<Datepicker
								testID="employee-calculator_date-picker"
								value={employeeState.date}
								onChange={(event) => {
									const state = { ...employeeState, date: event.target.value };
									setEmployeeState(state);
									resetEmployeeState(state);
								}}
							/>
						</div>
						{employeeState.vacationRule == 'Procentregeln' ? (
							<div className={styles.textField}>
								<div className={styles.textFieldHeading}>
									<Heading title={t`Uppskattad total lön under året`} as="div" size="h6" />
								</div>
								<TextField
									id="employee-calculator_total-salary-year-field"
									title={t`Total lön under året`}
									testID="employee-calculator_total-salary-year-field"
									type="number"
									value={employeeState.formattedEstimatedSalary}
									onChange={(event) => {
										const state = {
											...employeeState,
											formattedEstimatedSalary: event.target.value,
										};
										setEmployeeState(state);
										resetEmployeeState(state);
									}}
									required
									className={styles.numberInputFields}
								/>
							</div>
						) : (
							<div className={styles.textField}>
								<div className={styles.textFieldHeading}>
									<Heading title={t`Månadslön - kr (innan skatt)`} as="div" size="h6" />
								</div>
								<TextField
									title={t`Månadslön`}
									testID="employee-calculator_monthly-salary-field"
									type="number"
									value={employeeState.formattedMonthlyPay}
									onChange={(event) => {
										const state = {
											...employeeState,
											formattedMonthlyPay: event.target.value,
										};
										setEmployeeState(state);
										resetEmployeeState(state);
									}}
									required
									className={styles.numberInputFields}
								/>
							</div>
						)}
						<div className={styles.textFieldHeading}>
							<Heading title={t`Semester`} as="div" size="h6" />
						</div>
						<div className={employeeCalculatorStyles.vacationContainer}>
							<div className={employeeCalculatorStyles.incrementContainer}>
								<Incrementer
									size="small"
									defaultValue={25}
									onValueChange={(value) => {
										setEmployeeState({ ...employeeState, vacationDays: value });
									}}
									value={employeeState.vacationDays}
								/>
								<Text marginBottom="none" size="ingress">{t`Semesterdagar`}</Text>
							</div>
							<Divider className={styles.divider} />
							<VacationPicker
								blok={blok}
								onChange={(event: any) => {
									setEmployeeState({ ...employeeState, vacationRule: event.target.value });
								}}
							/>
						</div>

						<div className={styles.textField}>
							<div className={styles.textFieldHeading}>
								<Heading title={t`Växa-stöd`} as="div" size="h6" />
								<Tooltip theme="darkGreen" className={styles.toolTip} text={blok.growthSupportTooltip[0]?.text ?? ''} />
							</div>
							<GrowthSupport
								hasGrowthSupport={employeeState.growthSupport === 'Ja'}
								onChange={(event) => {
									setEmployeeState({ ...employeeState, growthSupport: event.target.value });
								}}
							/>
						</div>

						<Button
							disabled={
								employeeState.date == '' ||
								(employeeState.vacationRule == 'Sammalöneregeln' && employeeState.formattedMonthlyPay == '') ||
								(employeeState.vacationRule == 'Procentregeln' && employeeState.formattedEstimatedSalary == '')
							}
							type="submit"
							size="large"
							className={styles.calculateButton}
							testID="employee-calculator_compute-button"
						>{t`Beräkna`}</Button>

						{(isMonthlyPayNotEmpty || isSalaryAndDateNotEmpty) && (
							<div className={styles.clearFieldsBtnContainer}>
								<button
									type="button"
									className={styles.clearFieldsButton}
									onClick={() => {
										setEmployeeState({
											formattedMonthlyPay: '',
											formattedEstimatedSalary: '',
											date: '',
											vacationDays: 25,
											vacationRule: 'Sammalöneregeln',
											growthSupport: 'Nej',
										});
										setEmployeeCalculationInfo({
											averageMonthlyCost: 0,
											employeeFee: 0,
											salary: 0,
											holidayAllowance: 0,
											insuranceCost: 0,
											specialSalaryTax: 0,
										});
									}}
									data-test-id="employee-calculator_clear-fields"
								>{t`Rensa`}</button>
							</div>
						)}
					</form>
				</Grid.Item>
				<Grid.Item className={styles.description}>
					{employeeCalculationInfo.averageMonthlyCost ? (
						<>
							<Heading as="div" title={t`Kostnad`} size="h5" marginBottom="sm" />
							<div className={employeeCalculatorStyles.chartContainer}>
								<div className={employeeCalculatorStyles.chart}>
									<div>
										<canvas id="employeeChart" />
										<div className={employeeCalculatorStyles.costContainer}>
											<span className={employeeCalculatorStyles.monthlyCost}>
												{formatMoney(employeeCalculationInfo.averageMonthlyCost)}
											</span>
											<span className={employeeCalculatorStyles.curreny}>{t`kr/mån`}</span>
										</div>
									</div>
								</div>
							</div>
							<CalculationResultRow
								lineColor="pink"
								text={t`Månadslön (innan skatt)`}
								amount={employeeCalculationInfo.salary}
								className={employeeCalculatorStyles.pinkLine}
							/>
							<CalculationResultRow
								lineColor="purple"
								text={t`Arbetsgivaravgifter`}
								amount={employeeCalculationInfo.employeeFee}
								className={employeeCalculatorStyles.purpleLine}
							/>
							<CalculationResultRow
								lineColor="yellow"
								text={t`Semesterlön`}
								amount={employeeCalculationInfo.holidayAllowance}
								className={employeeCalculatorStyles.yellowLine}
							/>
							<CalculationResultRow
								lineColor="blue"
								text={t`Försäkringskostnader`}
								amount={employeeCalculationInfo.insuranceCost}
								className={employeeCalculatorStyles.blueLine}
							/>
							<CalculationResultRow
								lineColor="lightpurple"
								text={t`Särskild löneskatt`}
								amount={employeeCalculationInfo.specialSalaryTax}
								className={employeeCalculatorStyles.lightPurpleLine}
							/>
						</>
					) : (
						<>
							{calculationText?.calculationHeader && (
								<span className={styles.calculationHeader}>
									<Heading as="h3" title={`${calculationText?.calculationHeader}`} size="h6" />
								</span>
							)}
							<Text>{calculationText?.calculationDescription}</Text>
						</>
					)}
				</Grid.Item>
			</Grid>
		</ContainerFluid>
	);
};
