import { ContainerFluid, Grid, Heading, PageLayout, Section, Text } from '@/atoms';
import { CardFiveGroupStoryblok, NavigationPageIntegrationsStoryblok, SubMenuStoryblok } from '@/components';
import { useIntegrations } from '@/contexts/Integration';
import { useSearchIntegrations } from '@/contexts/Integration/searchIntegrationsContext';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ISbStoryData } from '@/types/storyblok';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { NextSeo } from 'next-seo';
import React from 'react';
import DynamicComponent from '../../DynamicComponent';
import { IntegrationCard } from './IntegrationCard/IntegrationCard';
import styles from './NavigationPageIntegrations.module.scss';
import { SearchIntegrations } from './SearchIntegrations/SearchIntegrations';
import { SearchResult } from './SearchIntegrations/SearchResult';
import { SortIntegrations } from './SearchIntegrations/SortIntegrations';
import { withSearchIntegrations } from './SearchIntegrations/withSearchIntegrations';
import { ShortCuts } from './ShortCuts/ShortCuts';

interface Props {
	blok: NavigationPageIntegrationsStoryblok;
	meta: Record<string, any>;
}

const NavigationPageIntegrationsWithSearch: React.FC<Props> = ({ blok, meta }) => {
	const { searchValue, selectedCategory, selectedCompany } = useSearchIntegrations();
	const { featuredIntegrations, categories } = useIntegrations();
	const { headerRecommended, headerAll, subtitle } = blok;

	const header = Array.isArray(blok.header) ? blok.header[0] : blok.header;

	const showFeatured = !searchValue && !selectedCompany.value && !selectedCategory.value;

	// Should this still be used?
	const subMenu = blok?.subMenu as unknown as ISbStoryData<SubMenuStoryblok>;
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;
	const { hasSubMenu } = useSubMenu();

	const categoryMeta = categories?.find(
		(category) => category.name.toLowerCase() === selectedCategory.value?.toLowerCase(),
	);

	const hasFeaturedIntegrations = featuredIntegrations!.length > 0;

	const showFeaturedIntegrations = (() => {
		return hasFeaturedIntegrations && showFeatured;
	})();

	const showCategoryDescription = (() => {
		return categoryMeta?.description ? true : false;
	})();

	return (
		<PageLayout subMenu={subMenu} exits={contact} meta={meta}>
			{categoryMeta && (
				<NextSeo title={`Integrationer för ${categoryMeta.name}`} description={categoryMeta.description} />
			)}
			<div {...editableBlok(blok)} className={styles.container}>
				{header && <DynamicComponent key={blok._uid} blok={header} meta={{ zone: 1 }} />}

				<ContainerFluid>
					<Section paddingY={{ base: 'xl' }}>
						<Heading
							className={styles.subtitle}
							marginBottom="3xl"
							as="h2"
							title={subtitle}
							data-testid="navigation-page-integrations-header-h2"
						/>
						<SearchIntegrations />
						<ShortCuts />
					</Section>
				</ContainerFluid>

				{showFeaturedIntegrations && (
					<ContainerFluid>
						<Section paddingY={{ base: 'xl' }}>
							{headerRecommended?.map((blok) => <DynamicComponent key={blok._uid} blok={blok} meta={{ zone: 1 }} />)}
							<Grid
								columns={hasSubMenu ? { base: 1, md: 2, lg: 2 } : { base: 1, md: 2, lg: 2, xl: 2 }}
								colGap="xl"
								rowGap="xl"
							>
								{featuredIntegrations!.map((integration) => (
									<Grid.Item key={integration.appId}>
										<IntegrationCard integration={integration} size="medium" />
									</Grid.Item>
								))}
							</Grid>
						</Section>
					</ContainerFluid>
				)}

				{showCategoryDescription && (
					<ContainerFluid>
						<Section paddingY={{ base: 'xl' }} className={styles.categoryDescription}>
							<Grid columns={{ base: 2 }}>
								<Grid.Item>
									<Heading as="h3" title={selectedCategory.value ?? ''} />
									<Text>{categoryMeta?.description}</Text>
								</Grid.Item>
							</Grid>
						</Section>
					</ContainerFluid>
				)}

				<ContainerFluid>
					<Section paddingY={{ base: 'xl' }} className={styles.filteredIntegrations}>
						{showFeatured && headerAll?.map((blok) => <DynamicComponent key={blok._uid} blok={blok} />)}

						<SortIntegrations />

						<SearchResult />
					</Section>
				</ContainerFluid>

				<div className={cls(styles.zone2)}>
					{blok.zone2?.map((blok: any) => (
						<DynamicComponent key={blok._uid} blok={blok} meta={{ zone: 2, theme: 'light-green' }} />
					))}
				</div>

				{blok.zone3?.map((blok: any) => <DynamicComponent key={blok._uid} blok={blok} meta={{ zone: 3 }} />)}

				{blok.zone4?.map((blok: any) => <DynamicComponent key={blok._uid} blok={blok} meta={{ zone: 4 }} />)}
			</div>
		</PageLayout>
	);
};

export const NavigationPageIntegrations = withSearchIntegrations(NavigationPageIntegrationsWithSearch);
