import { ContainerFluid, Section } from '@/atoms';
import { CardSmallIcon, CardSmallIconProps } from '@/bloks/Card/CardSmall';
import { HeaderCommon, HeaderCommonProps } from '@/bloks/Other';
import { CardSmallIconGroupStoryblok, SectionCardSidescrollStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { cls } from '@/utils';
import { gsap, useGSAP } from '@/utils/gsap';
import { editableBlok } from '@/utils/storyblok';
import { useEffect, useRef, useState } from 'react';
import styles from './SectionCardSidescroll.module.scss';

interface BlokProps {
	blok: SectionCardSidescrollStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	header: blok.header?.[0] && HeaderCommon.blokProps({ blok: blok.header?.[0] }),
	cards: blok.cardGroup?.[0]?.cards.map((card) => CardSmallIcon.blokProps({ blok: card })),
	buttonVariant: blok.cardGroup[0].buttonVariant,
	_editable: blok._editable,
});

interface Props {
	header?: HeaderCommonProps;
	cards: CardSmallIconProps[];
	buttonVariant?: CardSmallIconGroupStoryblok['buttonVariant'];
	_editable?: string;
}

export function SectionCardSidescroll({ header, cards, buttonVariant, _editable }: Props) {
	const theme = 'white';
	const container = useRef<HTMLDivElement | null>(null);
	const cardContainer = useRef<HTMLDivElement | null>(null);
	const [navBarHeight, setNavBarHeight] = useState(78);
	const { hasSubMenu } = useSubMenu();

	useEffect(() => {
		setNavBarHeight(Math.floor(document.querySelector('header')?.parentElement?.getBoundingClientRect().height ?? 0));
	}, []);

	useGSAP(
		() => {
			if (cards?.length === 2) {
				return;
			}

			if (!cardContainer.current || !container.current) {
				return;
			}

			const cardElemWidth = document.querySelectorAll('.animated-card')[0].getBoundingClientRect().width;
			const cardContainerWidth = cardContainer.current.getBoundingClientRect().width;
			const visibleCards = cardContainerWidth / cardElemWidth;
			const hiddenCardContainerWidth = (cards?.length - visibleCards) * cardElemWidth;
			const offset = hasSubMenu ? navBarHeight + 16 : navBarHeight;

			gsap.to(cardContainer.current, {
				x: -hiddenCardContainerWidth,
				duration: 3,
				ease: 'none',
				scrollTrigger: {
					trigger: container.current,
					start: `top ${offset}`,
					toggleActions: 'restart pause reverse pause',
					scrub: true,
					pin: true,
				},
			});
		},
		{ scope: container, dependencies: [cards?.length, navBarHeight], revertOnUpdate: true },
	);

	return (
		<div ref={container}>
			<ContainerFluid
				{...editableBlok({ _editable })}
				theme={theme}
				className={cls(styles.container, hasSubMenu && styles.hasSubMenu)}
			>
				<Section>
					{header && <HeaderCommon {...header} layout="center" marginBottom="3xl" />}
					<div className={styles.cardContainer} ref={cardContainer}>
						{cards?.map((card, index) => (
							<div className={cls(styles.card, 'animated-card')} key={index}>
								<CardSmallIcon {...card} buttonVariant={buttonVariant} className="card" />
							</div>
						))}
					</div>
				</Section>
			</ContainerFluid>
		</div>
	);
}

SectionCardSidescroll.blokProps = blokProps;
