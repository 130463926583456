import { BrandLine, BrandStripes, ContainerFluid, Heading, Section, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button/LinkButton';
import { renderRichText } from '@/bloks/RichText';
import { HeaderStoryblok, LinkButtonStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { Themes } from '@/types';
import { cls } from '@/utils';
import { usePiwikPro } from '@piwikpro/next-piwik-pro';
import React from 'react';
import { Document } from 'storyblok-rich-text-react-renderer';
import styles from './Header.module.scss';

interface BlokProps {
	blok: HeaderStoryblok;
}

interface HeaderProps {
	label?: string;
	h1Part1: string;
	h1Part2?: string;
	shortText?: Document | string;
	linkButtons?: LinkButtonStoryblok[];
	textLayout?: 'left' | 'center';
	theme?: Themes['theme'];
	compact?: boolean;
}

const blokProps = ({ blok }: BlokProps): HeaderProps => ({
	label: blok?.label,
	h1Part1: blok?.h1Part1,
	h1Part2: blok?.h1Part2,
	shortText: blok?.shortText,
	linkButtons: blok?.linkButtons as LinkButtonStoryblok[],
	textLayout: blok?.textLayout,
	theme: blok?.theme?.[0]?.theme,
});

export const Header = ({
	label,
	h1Part1,
	h1Part2,
	shortText,
	linkButtons,
	textLayout,
	theme = 'darkGreen',
	compact = false,
}: HeaderProps) => {
	const brandStripes = textLayout === 'center' ? <BrandLine marginBottom="xl" /> : <BrandStripes />;
	const { hasSubMenu } = useSubMenu();

	// PIWIK IMPRESSION TRACKING
	const { ContentTracking, DataLayer } = usePiwikPro();
	const contentName = `1|Header`;
	const contentPiece = h1Part2 ? `${h1Part1} ${h1Part2}` : h1Part1;
	let contentTarget = linkButtons?.length ? 'multiple interactions possible' : 'no interactions possible';
	if (linkButtons?.length === 1) contentTarget = 'one interaction possible';

	function trackContentInteraction(targetText: string) {
		DataLayer.push({
			event: 'contentInteraction',
			contentName,
			contentPiece,
			contentTarget,
			contentInteraction: 'Click',
			data: { clickElementText: targetText },
		});
		ContentTracking.trackContentInteraction('contentInteraction', contentName, contentPiece, contentTarget);
	}

	return (
		<ImpressionTrackingProvider value={{ contentName, contentPiece, contentTarget }}>
			<ContainerFluid
				theme={theme === 'dark-green' ? 'darkGreen' : theme}
				className={cls(styles.container, hasSubMenu && styles.hasSubMenu, compact && styles.compact)}
			>
				<Section
					as="header"
					className={cls(styles.section, styles[`theme--${theme}`], styles[`layout--${textLayout}`])}
				>
					<div className={styles.content}>
						<div className={styles.inner}>
							{brandStripes}
							{label && <Heading as="div" size="h5" title={label} marginBottom="lg" />}
							<Heading as="h1" title={h1Part1} titlePart2={h1Part2} marginBottom="lg" />
							{typeof shortText !== 'string' &&
								shortText &&
								shortText.content?.[0]?.content &&
								renderRichText(shortText, {
									textSize: 'ingress',
									textMarginBottom: linkButtons && linkButtons?.length > 0 ? 'xl' : 'none',
								})}

							{typeof shortText === 'string' && (
								<Text marginBottom={linkButtons && linkButtons?.length > 0 ? 'xl' : 'none'}>{shortText}</Text>
							)}
							{!!linkButtons?.length && (
								<div className={styles.linkButtons}>
									{linkButtons?.map((btn: LinkButtonStoryblok, index: number) => (
										<LinkButton
											key={btn._uid}
											{...LinkButton.blokProps({ blok: btn })}
											variant={index === 0 ? 'filled' : 'outlined'}
											onClick={() => {
												trackContentInteraction(btn.text ?? '');
											}}
										/>
									))}
								</div>
							)}
						</div>
					</div>
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};

Header.blokProps = blokProps;
