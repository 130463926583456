import { ImageStoryblok } from '@/components';
import { ImageAsset, ImageAssetProps } from '@/metabloks';

interface BlokProps {
	blok: ImageStoryblok;
	meta?: {
		fill?: boolean;
		objectFit?: 'cover';
		objectPosition?: 'center';
	};
}

const blokProps = ({ blok, meta }: BlokProps): Props => {
	const props: Props = {
		image: blok.image,
	};

	if (meta) {
		const { fill, objectFit, objectPosition } = meta;
		Object.assign(props, { fill, objectFit, objectPosition });
	}

	return props;
};

interface Props {
	fill?: boolean;
	image: ImageAssetProps;
	objectFit?: 'cover';
	objectPosition?: 'center';
}

/**
 * Image component.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=249%3A9072
 */
export function Image({ fill, image, objectFit, objectPosition }: Props) {
	return (
		<>
			{image?.filename && (
				<ImageAsset
					absolute
					fill={fill}
					asset={image}
					objectFit={objectFit && objectFit}
					objectPosition={objectPosition && objectPosition}
				/>
			)}
		</>
	);
}

Image.blokProps = blokProps;
