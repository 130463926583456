import { BackgroundImage, ContainerFluid, Section } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { HeaderCommon, HeaderCommonProps } from '@/bloks/Other';
import { SectionBackgroundImageStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import type { ImageAssetProps } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { LinkButton, LinkButtonProps } from '../../Button';
import styles from './SectionBackgroundImage.module.scss';

interface BlokProps {
	blok: SectionBackgroundImageStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	layout: blok.layout,
	topLinks: blok.topLinks?.map((link) => ({ ...LinkButton.blokProps({ blok: link }) })),
	header: blok.header?.[0] && { ...HeaderCommon.blokProps({ blok: blok.header?.[0] }) },
	backgroundImage: blok.backgroundImage,
	_editable: blok._editable,
});

interface Props {
	layout?: 'default' | 'textRightImageLeft';
	topLinks?: LinkButtonProps[];
	header?: HeaderCommonProps;
	backgroundImage: ImageAssetProps;
	_editable?: string;
}
export const SectionBackgroundImage: Blok<Props, BlokProps> = ({
	layout,
	topLinks,
	header,
	backgroundImage,
	_editable,
}) => {
	const { hasSubMenu } = useSubMenu();

	return (
		<ContainerFluid theme="dark-green">
			{backgroundImage?.filename && (
				<BackgroundImage
					asset={backgroundImage}
					overlay
					overlayDirection={layout === 'textRightImageLeft' ? 'to-left' : 'to-right'}
					objectPosition="center top"
				/>
			)}
			<Section {...editableBlok({ _editable })} className={cls(styles.container, hasSubMenu && styles.hasSubMenu)}>
				<div className={cls(styles.content, styles[`layout--${layout}`])}>
					{header && <HeaderCommon {...header} buttonVariant="outlined" adjustableTextWidth={false} />}
				</div>

				{layout !== 'textRightImageLeft' && topLinks && (
					<div className={styles.topLinkContainer}>
						{topLinks?.map((link) => <LinkButton key={link.children as string} {...link} variant="text" />)}
					</div>
				)}
			</Section>
		</ContainerFluid>
	);
};

SectionBackgroundImage.blokProps = blokProps;
