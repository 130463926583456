import { GridProps } from '@/atoms';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';

/**
 * A common hook for grid columns based on items in grid.
 * Takes into account if sub menu exists or not and ajdusts to that.
 *
 * @param itemCount
 * @returns GridProps['columns']
 */
export const useGridColumns = (itemCount: number): GridProps['columns'] => {
	const { hasSubMenu } = useSubMenu();
	const columns: GridProps['columns'] = { base: 1 };

	if (itemCount === 1) {
		columns.lg = 2;
		return columns;
	}

	if (itemCount % 4 === 0) {
		columns.xxl = 4;

		if (hasSubMenu) {
			columns.xl = 2;
		}

		columns.lg = 2;
	}
	if (itemCount % 3 === 0 || itemCount % 7 === 0 || itemCount % 5 === 0) {
		columns.lg = 3;
		columns.xl = 3;
	}
	if (itemCount % 2 === 0) {
		columns.md = 2;
	}
	return columns;
};
