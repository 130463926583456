import { useTheme } from '@/contexts/theme';
import { cls } from '@/utils';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { Heading } from '../Heading';
import styles from './Slider.module.scss';

interface SliderProps {
	min: number;
	max: number;
	defaultValue?: number;
	valueLabel?: string;
	label?: string;
	labelClassName?: string;
	onSliderChange?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
	step?: number;
	onInput?: (e: FormEvent<HTMLInputElement>) => void;
	steps?: number[];
}

export const Slider: React.FC<SliderProps> = ({
	min,
	max,
	defaultValue,
	valueLabel,
	label,
	onSliderChange,
	labelClassName,
	step,
	onInput,
	steps,
}) => {
	const [percentage, setPercentage] = useState<number>(0);
	const theme = useTheme();
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (!inputRef.current) {
			return;
		}
		if (!defaultValue) {
			return;
		}

		inputRef.current.value = String(defaultValue);
		const val = Number(defaultValue);
		const percent = ((val - min) / (max - min)) * 100;
		setPercentage(percent);
	}, [defaultValue, min, max]);
	return (
		<div className={styles.container}>
			<div className={styles.labelContainer}>
				{label && <Heading as="div" size="h5" title={label} marginBottom="none" className={labelClassName} />}
				{valueLabel && <Heading as="div" size="h5" title={valueLabel} marginBottom="none" className={labelClassName} />}
			</div>
			<div className={styles.sliderContainer}>
				<div className={styles.trackAfter} />
				<div
					className={cls(styles.progressTrack, theme.styles.brandlineColor)}
					style={{
						background:
							'linear-gradient(to right, var(--brand-stripe-1-bg) 0%, var(--brand-stripe-1-bg) ' +
							percentage +
							'%, transparent ' +
							percentage +
							'%, transparent 100%)',
					}}
				/>
				<input
					type="range"
					min={min}
					max={max}
					onInput={(e) => {
						onInput && onInput(e);
						const val = Number(e.currentTarget.value);
						const percent = ((val - min) / (max - min)) * 100;
						setPercentage(percent);
					}}
					onMouseUp={onSliderChange && onSliderChange}
					defaultValue={defaultValue ?? min}
					className={theme.styles.brandlineColor}
					step={step}
					list="step-list"
					ref={inputRef}
				/>
			</div>
			{steps && (
				<datalist id="step-list" className={styles.steplist}>
					{steps.map((step, i) => (
						<option key={i} value={step} />
					))}
				</datalist>
			)}
		</div>
	);
};
