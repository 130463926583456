import { BrandLine, ContainerFluid, Grid, Heading, Section } from '@/atoms';
import { LinkButtonStoryblok, SectionLargeStoryblok, ThemeStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ImageAsset } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { LinkButton } from '../../Button';
import { renderRichText } from '../../RichText';
import styles from './SectionLarge.module.scss';

interface Props {
	blok: SectionLargeStoryblok;
}

/**
 * @deprecated Will be replaced by CardLarge
 *
 * Section component that renders a big title to the left and optional image on the right
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=158%3A6693
 */
export const SectionLarge: React.FC<Props> = ({ blok }) => {
	const { image, smallTitle, titlePart1, titlePart2, text, linkButtons } = blok;
	const theme = blok.theme?.map((t: ThemeStoryblok) => t.theme)?.[0] || 'white';
	const layout = blok.imagePosition || 'right';
	const { hasSubMenu } = useSubMenu();

	return (
		<ContainerFluid theme={theme}>
			<Section
				{...editableBlok(blok)}
				className={cls(styles.container, styles[`theme--${theme}`], styles[`layout--${layout}`])}
			>
				<Grid columns={{ base: 1, lg: image?.filename ? 2 : 1 }} className={styles.gridContainer}>
					<Grid.Item
						className={cls(
							styles.content,
							!image?.filename && styles.contentWithoutImage,
							hasSubMenu && styles.hasSubMenu,
						)}
					>
						<BrandLine />
						{smallTitle && (
							<Heading
								as="div"
								size="h6"
								className={styles.smallTitle}
								title={smallTitle}
								data-testid="LargeGreenSection-header-h6"
								marginBottom="xl"
							/>
						)}
						<div className={styles.titles}>
							{titlePart1 && (
								<Heading as="h2" data-testid="LargeGreenSection-header-h2" title={titlePart1} titlePart2={titlePart2} />
							)}
						</div>
						{text && <div className={styles.text}>{renderRichText(text)}</div>}

						{linkButtons && linkButtons?.length > 0 && (
							<div className={styles.linkButtons}>
								{linkButtons.map((button: LinkButtonStoryblok) => (
									<LinkButton
										key={button._uid}
										{...LinkButton.blokProps({ blok: button })}
										variant="filled"
										className={styles.btn}
									/>
								))}
							</div>
						)}
					</Grid.Item>

					{image?.filename && (
						<Grid.Item className={styles.image}>
							<ImageAsset absolute asset={image} objectFit="cover" fill objectPosition="center" filters="smart" />
						</Grid.Item>
					)}
				</Grid>
			</Section>
		</ContainerFluid>
	);
};
