import { CaptchaForm, Checkbox, Grid, Select, TextField } from '@/atoms';
import { FieldValidationProvider, FormValidationProvider } from '@/atoms/CaptchaForm/FormValidationContext';
import { getCaptchaSolution } from '@/atoms/CaptchaForm/FriendlyCaptcha';
import { Blok } from '@/bloks/Blok';
import { PhoneLinkStoryblok, UpsalesFormStoryblok } from '@/components';
import { ISbStoryData } from '@/types/storyblok';
import { isNotString, slugifyName } from '@/utils';
import { fetchApi } from '@/utils/fetchApi';
import { hostnameWithoutSubdomain } from '@/utils/hostnameWithoutSubdomain';
import { validateEmailField, validatePhoneField } from '@/validation';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import styles from './../Form.module.scss';

const hostname = global.window?.location?.hostname;
const domain = hostnameWithoutSubdomain(hostname);

export interface UpsalesField {
	name: string;
	placeholder?: string;
	required?: boolean;
	hidden?: boolean;
	textarea?: boolean;
	numberField?: boolean;
	checkbox?: boolean;
	dropDown?: boolean;
	dropDownOptions?: { value: string; label: string; id: string }[];
}

interface UpsalesFormMeta {
	theme?: string;
	layout?: 'twoCols' | string;
	parent?: string;
	zone?: number | 'popup' | undefined;
	afterSubmit?: () => void;
	compactFields?: boolean;
	phoneLink?: PhoneLinkStoryblok[];
	numberOfTimesShown?: number;
}

interface Props {
	formId: string;
	feedbackTitle: string;
	feedbackText: string;
	inputFields: UpsalesField[];
	formName: string;
	meta?: UpsalesFormMeta;
}

interface BlokProps {
	story: ISbStoryData<UpsalesFormStoryblok>;
	meta?: UpsalesFormMeta;
}

const blokProps = ({ story, meta }: BlokProps): Props => ({
	formId: story?.content?.formId,
	feedbackTitle: story?.content?.feedbackTitle,
	feedbackText: story?.content?.feedbackText,
	inputFields: story?.content?.inputFields?.filter(isNotString).map((field) => ({
		name: field?.content?.name,
		placeholder: field?.content?.placeholder,
		required: field?.content?.required,
		hidden: field?.content?.hidden,
		textarea: field?.content?.textarea,
		numberField: field?.content?.numberField,
		checkbox: field?.content?.checkbox,
		dropDown: field?.content?.dropDown,
		dropDownOptions: field?.content?.dropDownOptions?.map((option) => {
			return {
				value: option.value ? option.value : option.name,
				label: option.name,
				id: slugifyName(option.name),
			};
		}),
	})),
	formName: story?.slug,
	meta,
});

export const UpsalesForm: Blok<Props, BlokProps> = ({
	formId,
	feedbackTitle,
	feedbackText,
	inputFields,
	formName,
	meta,
}) => {
	const isProdEnvironment = domain === 'fortnox.se';
	const customUrlField = isProdEnvironment ? 'Contact.custom_24' : 'Contact.custom_15';
	const filteredInputFields = inputFields?.filter((field) => !field.textarea && field.name !== customUrlField);
	const textAreas = inputFields?.filter((field) => field.textarea === true);
	const formCid = '9640';
	const popupField = isProdEnvironment ? 'Contact.custom_61' : 'Extra.1699947248508';
	const includePath = inputFields
		?.filter((field) => field.name === customUrlField)
		?.map(Boolean)
		?.includes(true);

	const router = useRouter();

	const getProvidedQueryParam = (query: string) => {
		const param = new URLSearchParams(`?${router.asPath.split('?')[1]}`).get(query) ?? undefined;
		return param?.replace(/[^a-zA-Z0-9-_]/g, '');
	};

	const invitationCodeField = inputFields?.find((field) => field.placeholder === 'invitation-code');
	const providedInvitationCode =
		invitationCodeField?.placeholder && getProvidedQueryParam(invitationCodeField.placeholder);

	const columns =
		meta?.layout === 'twoCols' && filteredInputFields?.length > 1
			? { base: 1, md: 2, lg: 2, xl: 2 }
			: { base: 1, xl: 1, lg: 1, md: 1 };
	const oddAmountOfFields = filteredInputFields?.length % 1 === 0;
	const compactFields = meta?.compactFields;

	const handleOnSubmit = (e: React.BaseSyntheticEvent, honeypotTriggered?: boolean) => {
		const urlSearchParams = new URLSearchParams();

		const body = {
			data: '',
			captchaSolution: getCaptchaSolution(e),
		};

		const formData = new FormData(e.target);
		formData.forEach(function (value, prop) {
			if (prop === 'Client.orgNo') {
				const orgNr = value.toString().replace(/-|\s/g, '');
				urlSearchParams.append(prop, orgNr);

				return;
			}
			if (prop !== 'frc-captcha-solution') {
				urlSearchParams.append(prop, value as string);
			}
			if (prop === invitationCodeField?.name && providedInvitationCode) {
				urlSearchParams.set(prop, providedInvitationCode);
			}
		});

		urlSearchParams.append('formId', formId);
		urlSearchParams.append('formCid', formCid);
		urlSearchParams.append('isFrame', 'false');
		urlSearchParams.append('validation', '');
		urlSearchParams.append('isAjax', 'true');

		if (includePath) {
			urlSearchParams.append(customUrlField, router.asPath);
		}

		if (meta?.numberOfTimesShown) {
			urlSearchParams.append(popupField, meta?.numberOfTimesShown.toString());
		}

		body.data = urlSearchParams.toString();

		return async function postUpsalesForm() {
			if (honeypotTriggered) {
				throw Error('honeypot.triggered.upsales');
			} else {
				await fetchApi('upsales-v1', body);
			}
			if (meta?.afterSubmit) {
				meta?.afterSubmit();
			}
		};
	};

	const getValidator = (fieldName: string) => {
		if (fieldName === 'Contact.phone') {
			return validatePhoneField;
		}

		if (fieldName === 'Contact.email') {
			return validateEmailField;
		}

		return null;
	};

	const getFieldType = (field: UpsalesField) => {
		if (field?.textarea) {
			return 'textarea';
		}
		if (field?.numberField) {
			return 'number';
		}
		return 'text';
	};
	return (
		<FormValidationProvider>
			<CaptchaForm
				name={`form_${formId}`}
				className={styles.form}
				onSubmit={handleOnSubmit}
				feedbackText={feedbackText}
				feedbackTitle={feedbackTitle}
				formId={`UPSALES-${formName}`}
				meta={{ ...meta, type: 'upsales', centerFeedbackText: true }}
			>
				<Grid columns={columns} colGap="lg">
					{filteredInputFields?.map((field, index: number) => {
						const lastField = index === inputFields.length - 1;

						return (
							<Grid.Item
								key={index}
								className={classnames({
									[styles.lastOddField]: lastField && oddAmountOfFields,
								})}
							>
								{field.dropDown ? (
									<div className={styles.textFieldContainer}>
										<Select
											data={field.dropDownOptions ?? []}
											title={field.placeholder ?? ''}
											id={`upsales-field-${index}-${formId}`}
											aria-label={field.placeholder ?? ''}
											name={field.name}
										/>
									</div>
								) : (
									<div className={styles.textFieldContainer}>
										<FieldValidationProvider id={field?.name}>
											{field.checkbox ? (
												<Checkbox
													label={field?.placeholder ?? ''}
													name={field?.name ?? ''}
													bordered={true}
													value={'on'}
												/>
											) : (
												<TextField
													id={`upsales-field-${index}-${formId}`}
													title={field?.placeholder ?? ''}
													name={field?.name ?? ''}
													required={field?.required ?? false}
													validators={getValidator(field?.name)}
													type={field?.hidden ? 'hidden' : getFieldType(field)}
													className={classnames({
														[styles.hiddenField]: field?.hidden,
													})}
													bordered
													compact={compactFields}
													maxLength={512}
												/>
											)}
										</FieldValidationProvider>
									</div>
								)}
							</Grid.Item>
						);
					})}
				</Grid>
				<>
					{textAreas?.map((field, index: number) => (
						<div className={styles.textFieldContainer} key={index}>
							<FieldValidationProvider id={field?.name}>
								<TextField
									id={`upsales-field-textarea-${index}-${formId}`}
									testID="buy-guide-upsale-form"
									title={field?.placeholder ?? ''}
									name={field?.name ?? ''}
									required={field?.required ?? false}
									validators={getValidator(field?.name)}
									type={field?.hidden ? 'hidden' : getFieldType(field)}
									className={classnames({
										[styles.hiddenField]: field?.hidden,
									})}
									bordered
									compact={compactFields}
									maxLength={512}
								/>
							</FieldValidationProvider>
						</div>
					))}
				</>
			</CaptchaForm>
		</FormValidationProvider>
	);
};

UpsalesForm.blokProps = blokProps;
