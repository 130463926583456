import { ArticleGroup } from '@/bloks';
import DynamicComponent from '@/bloks/DynamicComponent';
import { SubMenu } from '@/bloks/Menu';
import { CardFiveGroupStoryblok, SubMenuStoryblok } from '@/components';
import { ArticleCardProps } from '@/types/article';
import { ISbStoryData } from '@/types/storyblok';
import { cls } from '@/utils';
import React, { ReactNode, useEffect, useState } from 'react';
import { t } from 'ttag';
import styles from './PageLayout.module.scss';

interface Props {
	children: ReactNode;
	subMenu?: ISbStoryData<SubMenuStoryblok>;
	relatedArticles?: ArticleCardProps[];
	exits?: ISbStoryData<CardFiveGroupStoryblok>;
	meta: Record<any, any>;
}

export function PageLayout({ children, subMenu, relatedArticles, exits, meta }: Props) {
	const [onScroll, setOnScroll] = useState(false);

	useEffect(() => {
		window.onscroll = function () {
			scrollFunction();
		};
		return () => {
			window.onscroll = null;
		};
	}, []);

	function scrollFunction() {
		if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
			setOnScroll(true);
		} else {
			setOnScroll(false);
		}
	}

	return (
		<div className={styles.container}>
			{subMenu && typeof subMenu !== 'string' && (
				<div className={styles.mobileMenu}>
					{subMenu && typeof subMenu !== 'string' && (
						<SubMenu story={subMenu} meta={meta} className={cls(styles.subMenu, onScroll && styles.onScroll)} />
					)}
				</div>
			)}
			<div className={cls(styles.innerContainer, subMenu && styles.hasSubMenu)}>
				{subMenu && typeof subMenu !== 'string' && (
					<div className={styles.subMenuColumn}>
						{subMenu && typeof subMenu !== 'string' && (
							<aside className={cls(styles.subMenu, onScroll && styles.onScroll)}>
								<SubMenu story={subMenu} meta={meta} />
							</aside>
						)}
					</div>
				)}

				<div className={styles.contentColumn}>{children}</div>
			</div>

			{!!relatedArticles?.length && <ArticleGroup title={t`Relaterade artiklar`} articles={relatedArticles} />}

			{exits && <DynamicComponent blok={exits.content} meta={{ ...meta, zone: 5 }} />}
		</div>
	);
}
