import { GridProps } from '@/atoms';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { isOdd } from '@/utils/isOdd';

export function useCardSmallGridColumns(itemCount: number, group: boolean): GridProps['columns'] {
	const { hasSubMenu } = useSubMenu();
	const columns: GridProps['columns'] = { base: 1 };

	if (group) {
		columns.xxl = 2;
		columns.xl = 2;
		columns.lg = 2;
		columns.md = 2;

		return columns;
	}

	if (isOdd(itemCount)) {
		if (itemCount === 7) {
			columns.xxl = 8;
			columns.xl = hasSubMenu ? 2 : 8;
		}

		if (itemCount === 5) {
			columns.xxl = 6;
			columns.xl = hasSubMenu ? 2 : 6;
		}

		if (itemCount % 3 === 0) {
			columns.xxl = 3;
			columns.xl = hasSubMenu ? 2 : 3;
		}

		columns.lg = 4;
		columns.md = 4;

		return columns;
	}

	if (itemCount % 4 === 0) {
		columns.xxl = hasSubMenu ? 2 : 4;
		columns.xl = hasSubMenu ? 2 : 4;
		columns.lg = 2;
		columns.md = 2;

		return columns;
	}

	if (itemCount % 3 === 0) {
		columns.xxl = 3;
		columns.xl = hasSubMenu ? 2 : 3;
		columns.lg = 2;
		columns.md = 2;
		return columns;
	}

	if (itemCount % 2 === 0) {
		columns.xxl = 2;
		columns.xl = 2;
	}

	columns.md = 2;

	return columns;
}
