import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button/LinkButton';
import { LinkToggleStoryblok } from '@/components';
import { useAnimationContext } from '@/contexts/Animations';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ThemeProvider } from '@/contexts/theme';
import { getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { useRouter } from 'next/router';
import styles from './LinkToggle.module.scss';

interface BlokProps {
	blok: LinkToggleStoryblok;
}

const blokProps = ({ blok }: BlokProps): LinkToggleProps => ({
	linkButtons: blok.linkButtons.map((linkButton) => LinkButton.blokProps({ blok: linkButton })),
	theme: blok.theme,
	_editable: blok._editable,
});

export interface LinkToggleProps {
	linkButtons?: LinkButtonProps[];
	theme?: 'white' | 'green';
	_editable?: string;
}

export const LinkToggle: Blok<LinkToggleProps, BlokProps> = ({ linkButtons, _editable, theme }) => {
	const router = useRouter();
	const hasTheme = theme ? theme : 'green';
	const themeInfo = getThemeInfo(hasTheme);
	const secondaryTheme = themeInfo?.name === 'green' ? 'white' : 'green';
	const secondaryThemeInfo = getThemeInfo(secondaryTheme);
	const { setTriggerAnimation } = useAnimationContext();
	const { setTargetInteraction } = useImpressionTracking();

	return (
		<ThemeProvider theme={hasTheme}>
			<div {...editableBlok({ _editable })} className={cls(styles.linkToggle, themeInfo?.styles.bgColor)}>
				{linkButtons?.map((linkButton, index) => {
					const activeLink = router.asPath === linkButton.href;
					const isLastLink = index === linkButtons.length - 1;
					const inActiveButtonColor =
						themeInfo?.name === 'green'
							? secondaryThemeInfo?.styles?.buttonFilled
							: themeInfo?.styles?.linkToggleFilled;
					return (
						<div
							key={index}
							className={cls(
								styles.linkItem,
								linkButtons.length > 1 && !isLastLink && styles.smallerWidth,
								styles.lowerPaddingLeft,
							)}
						>
							<LinkButton
								href={linkButton.href}
								className={cls(
									styles.link,
									!activeLink && inActiveButtonColor,
									activeLink && styles.disableHoverEffect,
								)}
								onClick={() => {
									setTriggerAnimation(true);
									setTargetInteraction({ contentTarget: linkButton.children as string });
								}}
							>
								<span className={cls(activeLink && styles.activeLink, !activeLink && styles.inActiveLink)}>
									{linkButton.children}
								</span>
							</LinkButton>
						</div>
					);
				})}
			</div>
		</ThemeProvider>
	);
};
LinkToggle.blokProps = blokProps;
