import { isPlayableVimeoVideo } from '@/bloks/Page/NavigationPageEducation/CardEducation/CardEducation';
import { CardEducationStoryblok, LinkButtonStoryblok } from '@/components';
import { Course, EducationType, PriceName } from '@/contexts/eduadmin';
import { t } from 'ttag';

const getPrice = (course: Course) => {
	if (isPlayableVimeoVideo(course.EducationType as EducationType, course.VimeoId)) {
		return 0;
	}
	const priceNames = course.PriceNames as PriceName[];
	if (!priceNames || priceNames.length === 0) {
		return undefined;
	}
	const prices = priceNames.map((p) => p.Price).filter((price) => price !== undefined);
	return prices.length > 0 ? Math.min(...prices) : 0;
};

export const toCardEducation: (course: Course) => CardEducationStoryblok = (course) => {
	const vimeoRecording = isPlayableVimeoVideo(course.EducationType as EducationType, course.VimeoId);
	const price = getPrice(course);
	const link: LinkButtonStoryblok[] = [
		{
			_uid: course.CourseTemplateId.toString(),
			component: 'LinkButton',
			text: vimeoRecording ? t`Till utbildningen` : t`Läs mer & anmäl dig`,
			link: {
				url: '/utbildning/' + course.CourseTemplateId,
				linktype: 'story',
				target: '_self',
			},
		},
	];
	const cardEducationStoryblok: CardEducationStoryblok = {
		title: course.CourseName,
		educationType: course.EducationType as EducationType,
		text: course.Quote ?? '',
		targetGroups: course.TargetGroupsShort,
		subjects: course.Subjects,
		vimeoId: course.VimeoId,
		price: price?.toString(),
		link: link,
		_uid: course.CourseTemplateId.toString(),
		component: 'CardEducation',
	};
	return cardEducationStoryblok;
};
