import headingStyles from '@/atoms/Heading/Heading.module.scss';
import { PriceStructure } from '@/contexts/products';
import { useTheme } from '@/contexts/theme';
import cls from 'classnames';
import { t } from 'ttag';
import styles from './Price.module.scss';

const formatPrice = (price: number) => (Number.isInteger(price) ? price.toLocaleString('sv-SE') : price.toFixed(2));

function getInterval(interval: string) {
	switch (interval) {
		case 'st':
			return t`st`;
		case 'mån':
			return t`mån`;
		default:
			return interval; // untranslated in case a new interval is added and translation is not.
	}
}

type PriceProps = {
	className?: string;
	priceStructure: PriceStructure;
	unit?: string;
	interval?: string;
	firstPriceText?: string;
	secondPriceText?: string;
	size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'custom';
	showFrom?: boolean;
	crossedOutPrice?: boolean;
	priceText?: string;
};

export const Price: React.FC<PriceProps> = ({
	priceStructure,
	unit,
	interval,
	firstPriceText,
	secondPriceText,
	size = 'h2',
	showFrom = true,
	crossedOutPrice = false,
	priceText,
	...rest
}) => {
	const themeInfo = useTheme();

	if (!priceStructure) {
		return null;
	}

	const getPriceText = () => {
		if (priceText) {
			return priceText;
		}

		return (
			<>
				{unit}
				{interval ? '/' + getInterval(interval) : null}
			</>
		);
	};

	return (
		<div className={styles.container} {...rest}>
			{firstPriceText && (
				<div className={cls(headingStyles['size--h6'], themeInfo.styles.textColor)}>{firstPriceText}</div>
			)}
			<div className={styles.priceContainer}>
				<div className={styles.priceFrom}>
					{showFrom && <span className={cls(styles.from, themeInfo.styles.textColor)}>{t`Från`}</span>}
					<div
						className={cls(
							styles.price,
							headingStyles[`size--${size}`],
							themeInfo.styles.textColor,
							crossedOutPrice && styles.crossedOutPrice,
						)}
					>
						{formatPrice(priceStructure[12][0])}
					</div>
				</div>
				<span className={cls(themeInfo.styles.textColor, styles.priceText)}>{getPriceText()}</span>
			</div>
		</div>
	);
};
