import React, { createContext, useContext } from 'react';

export const useSubMenu = (): any => useContext(Context);

interface ContextProps {
	hasSubMenu: boolean;
}

const Context = createContext<ContextProps>({
	hasSubMenu: false,
});

interface Props {
	children: React.ReactNode;
	storyContent: any;
}

export const SubMenuProvider: React.FC<Props> = ({ children, storyContent }) => {
	const value = {
		hasSubMenu: typeof storyContent?.subMenu === 'object',
	};
	return <Context.Provider value={value}>{children}</Context.Provider>;
};
