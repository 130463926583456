import { Icon } from '@/atoms';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { useLightnessTheme, useTheme } from '@/contexts/theme';
import { ThemeInfo } from '@/themes';
import { cls } from '@/utils';
import styles from './Button.module.scss';

export type ButtonVariant = 'filled' | 'outlined' | 'text' | 'marklink';

export function getVariantStyles(variant: ButtonVariant, themeInfo: ThemeInfo) {
	switch (variant) {
		case 'outlined':
			return themeInfo.styles.buttonOutline;
		case 'text':
			return themeInfo.styles.textLink;
		case 'marklink':
			return themeInfo.styles.textLink;
		default:
			return themeInfo.styles.buttonFilled;
	}
}

interface Props {
	children: React.ReactNode;

	/**
	 * A function that will be invoked when the user clicks the component.
	 */
	onClick?: () => any;

	/**
	 * Sets whether or not the button is disabled.
	 */
	disabled?: boolean;

	/**
	 * Displays a loading icon inside the button if set to true.
	 */
	isLoading?: boolean;

	variant?: ButtonVariant;

	type?: 'button' | 'submit';

	size?: 'large';

	className?: string;

	testID?: string;

	id?: string;

	/**
	 * Lightness is used to decide color of elements placed on an image or video
	 */
	applyLightnessTheme?: boolean;
}

export const Button: React.FC<Props> = ({
	children,
	onClick,
	disabled,
	isLoading = false,
	variant = 'filled',
	type = 'submit',
	size,
	className,
	testID,
	id,
	applyLightnessTheme = false,
}) => {
	const { setTargetInteraction } = useImpressionTracking();

	const lightnessTheme = useLightnessTheme();
	const theme = useTheme();
	const themeInfo = applyLightnessTheme && lightnessTheme ? lightnessTheme : theme;
	const variantStyles = getVariantStyles(variant, themeInfo);

	return (
		<div>
			<button
				type={type}
				disabled={disabled}
				className={cls(styles.btn, styles[`variant--${variant}`], styles[`size--${size}`], variantStyles, className)}
				onClick={() => {
					if (onClick) onClick();
					setTargetInteraction({ contentTarget: (children as string) ?? '' });
				}}
				data-test-id={testID}
				id={id}
			>
				{isLoading ? (
					<Icon name="circle-notch" className="fa-spin" />
				) : (
					<span className={styles.inner}>{children}</span>
				)}
			</button>
		</div>
	);
};
