import { cls } from '@/utils';
import { IconName } from 'icons/types';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
import styles from './LargeSticker.module.scss';

interface Props {
	title: string;
	className?: string;
	icon?: IconName;
}

export const LargeSticker: React.FC<Props> = ({ title, className, icon }) => {
	return (
		<div className={cls(styles.container, className)}>
			{icon && (
				<div className={cls(styles.circle)}>
					<Icon name={icon} size="6x" color="--text-heading-color" />
				</div>
			)}
			<div className={cls(styles.sticker)}>
				<Heading as={`div`} size="h3" className={styles.largeHeader} title={title} marginBottom="none" />
			</div>
		</div>
	);
};
