import { Carousel, ContainerFluid, Divider, Grid, Heading, Image, Link, Section, Text } from '@/atoms';
import { Header } from '@/bloks';
import DynamicComponent from '@/bloks/DynamicComponent';
import { IntegrationPageStoryblok } from '@/components';
import { ImageType, Integration, useIntegrationCurrent, useIntegrations } from '@/contexts/Integration';
import { useServerPageConfig } from '@/contexts/config';
import { slugifyName } from '@/utils/slugify';
import { markdownToRichtext } from 'storyblok-markdown-richtext';
import { t } from 'ttag';
import { renderRichText } from '../../RichText';
import { IntegrationCard } from '../NavigationPageIntegrations/IntegrationCard/IntegrationCard';
import ContactInformation from './Components/ContactInformation';
import CustomerReviews from './Components/CustomerReviews';
import IntegrationHeader from './Components/IntegrationHeader';
import IntegrationPrice from './Components/IntegrationPrice';
import TagCloud from './Components/TagCloud';
import UniqueSellingPoints from './Components/UniqueSellingPoints';
import styles from './IntegrationPage.module.scss';

interface Props {
	blok: IntegrationPageStoryblok;
}

export const IntegrationPage: React.FC<Props> = ({ blok }) => {
	const integration = useIntegrationCurrent();
	const { integrations, integrator } = useIntegrations();
	const { appsHost: host } = useServerPageConfig();
	const { exits } = blok;

	const isCompanyPage = integrations && integrations.length > 0;

	const company: { companyName: string; apps: Integration[]; categories: string } = {
		companyName: '',
		apps: [],
		categories: '',
	};

	if (isCompanyPage) {
		company.companyName = integrations[0].contactInformation.companyName;
		company.apps = integrations;
		const categories = integrations.map((integration) => integration.categories).flat();
		const uniqueCategories = Array.from(new Set(categories.map((category) => category.categoryId))).map((id) =>
			categories.find((category) => category.categoryId === id),
		);

		company.categories = uniqueCategories.map((category) => category?.categoryNameSv).join(', ');
	}

	if (!integration) {
		return null;
	}

	const { appName } = integration;

	const integrationImages = integration.images.filter((image) => image.type !== ImageType.ICON);

	const summaryTitle = integration.summaryTitle ?? '';
	const about = markdownToRichtext(integration.about ?? '');
	const summary = markdownToRichtext(integration.summary ?? '');
	const howTo = markdownToRichtext(integration.howTo ?? '');

	const carouselImages: JSX.Element[] = integrationImages.reduce<JSX.Element[]>((acc, image, index) => {
		if (index % 2 === 0) {
			const nextImage = integrationImages[index + 1];

			if (nextImage) {
				acc.push(
					<div key={`image-${index}`} className={styles.slideStyle}>
						<div>
							<Image
								src={`${host}${image.path}`}
								alt={`Bild ${index} för integration`}
								width={500}
								height={326}
								loading="lazy"
								layout="responsive"
								className={styles.sliderImage}
							/>
						</div>
						<div>
							<Image
								src={`${host}${nextImage.path}`}
								alt={`Bild ${index + 1} för integration`}
								width={500}
								height={326}
								loading="lazy"
								layout="responsive"
								className={styles.sliderImage}
							/>
						</div>
					</div>,
				);
			}
		}

		return acc;
	}, []);

	return (
		<>
			{company.companyName ? (
				<ContainerFluid>
					<Header
						data-testid={`${slugifyName(appName)}-integration-header`}
						label={company.categories}
						h1Part1={`${(integrations ?? []).length > 1 ? 'Smarta' : 'Smart'} ${
							(integrations ?? []).length > 1 ? 'kopplingar' : 'koppling'
						} från`}
						h1Part2={company.companyName}
						compact
					/>

					<Section className={styles.container}>
						<Grid className={styles.integrationContainer} columns={{ base: 1, md: 3, xl: 4 }} colGap="xl" rowGap="xl">
							{integrations?.map((integration: Integration) => (
								<Grid.Item key={integration.appId}>
									<IntegrationCard integration={integration} />
								</Grid.Item>
							))}
						</Grid>
					</Section>

					<Section className={styles.container}>
						<Divider margin="xl" />
						<Grid className={styles.integrationContainer} columns={{ base: 1, md: 2, xl: 2 }} colGap="xl" rowGap="xl">
							<Grid.Item>
								{integrator?.companyLogoUrl && (
									<Image
										src={`${host}${integrator?.companyLogoUrl}`}
										height={140}
										width={400}
										alt={`Logotyp för ${integrator?.companyName}`}
										className={styles.companyLogo}
									/>
								)}
								<Heading as="h2" size="h5" title={`Om ${company.companyName}`} />
								{integrator?.about && <p>{integrator?.about}</p>}
								{integrator?.successStory && <p>{integrator?.successStory}</p>}
							</Grid.Item>
							<Grid.Item>
								<ContactInformation integrator={integrator} integration={integration} showAccessRights={false} />
							</Grid.Item>
						</Grid>
					</Section>
				</ContainerFluid>
			) : (
				<ContainerFluid>
					<Header
						data-testid={`${slugifyName(appName)}-integration-header`}
						h1Part1="Smart koppling: "
						h1Part2={appName}
						compact
					/>

					<Section className={styles.container}>
						<Grid className={styles.integrationGrid} columns={{ base: 1, lg: '9fr 3fr' }} colGap={'3xl'}>
							<Grid.Item className={styles.mainContent} data-testid="single-integration-content">
								<IntegrationHeader />

								<Carousel
									showIndicators={false}
									showArrows={true}
									centerSlidePercentage={100}
									className={styles.carousel}
									infiniteLoop={true}
								>
									{carouselImages}
								</Carousel>

								<Grid className={styles.integrationInfo} columns={{ base: 1, lg: 2 }} colGap="3xl">
									<Grid.Item>
										<Heading as="h3" size="h4" title={summaryTitle} />
										<Text as="div" className={styles.summary}>
											{renderRichText(summary)}
										</Text>
										<Text as="div" marginBottom="2xl">
											{renderRichText(about)}
										</Text>
										<Heading as="h3" size="h4" title={t`Så fungerar kopplingen med Fortnox`} />
										<Text as="div">{renderRichText(howTo)}</Text>
										{integration.readMoreUrl && (
											<Link
												href={integration.readMoreUrl}
											>{`Läs mer på ${integration.contactInformation.companyName}'s hemsida`}</Link> // Fixa detta!!
										)}
									</Grid.Item>
									<Grid.Item>
										{integration.uniqueSellingPoints.title && <UniqueSellingPoints />}
										<TagCloud />
									</Grid.Item>
								</Grid>
							</Grid.Item>

							<Grid.Item className={styles.sidebar}>
								<IntegrationPrice />
								<ContactInformation integrator={integrator} integration={integration} />
							</Grid.Item>
						</Grid>
					</Section>
					<Section padding={{ base: 'md', md: '3xl' }}>
						<Grid columns={{ base: 1, sm: 1, md: 2, lg: 4 }} colGap={'3xl'}>
							<Grid.Item colSpan={{ base: 4, sm: 4, md: 2, lg: 3 }}>
								<CustomerReviews reviews={integration.customerReviews} />
							</Grid.Item>
						</Grid>
					</Section>
				</ContainerFluid>
			)}

			{exits?.map((item) => <DynamicComponent key={item._uid} blok={item} />)}
		</>
	);
};
