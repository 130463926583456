import { BrandLine, Grid, Heading, PageLayout, Pagination, Section, Select, SelectOption, TextField } from '@/atoms';
import { AccountantCityList } from '@/bloks/Page/NavigationPageFindAccountants/AccountantCityList/AccountantCityList';
import {
	GeoLocationSearch,
	Position,
} from '@/bloks/Page/NavigationPageFindAccountants/GeolocationSearch/GeolocationSearch';
import {
	Choice,
	MultiChoiceFilter,
	filterMatch,
} from '@/bloks/Page/NavigationPageFindAccountants/MultiChoiceFilter/MultiChoiceFilter';
import { CardFiveGroupStoryblok, NavigationPageFindAccountantsStoryblok, SubMenuStoryblok } from '@/components';
import { useAccountants } from '@/contexts/accountants/AccountantsProvider';
import { Accountant } from '@/contexts/accountants/types';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ISbStoryData } from '@/types/storyblok';
import { capitalizeEachWord, cls, fetchApi } from '@/utils';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { t } from 'ttag';
import DynamicComponent from '../../DynamicComponent';
import { AccountantCard } from './AccountantCard/AccountantCard';
import styles from './NavigationPageFindAccountants.module.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const debounce = require('lodash.debounce');

interface Props {
	blok: NavigationPageFindAccountantsStoryblok;
	meta: Record<string, any>;
}

export const NavigationPageFindAccountants: React.FC<Props> = ({ blok, meta }) => {
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;
	const subMenu = blok?.subMenu as unknown as ISbStoryData<SubMenuStoryblok>;
	const MAX_PER_PAGE = 12;
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const { accountants, cities, searchAddress } = useAccountants();
	const [searchTerm, setSearchTerm] = useState('');
	const [searchField, setSearchField] = useState('');

	const filterChoices = [
		{
			key: 'bokföring / redovisning',
			whitelist: ['bokföring', 'bokföra', 'redovisning', 'redovisa'],
			blacklist: ['årsredovisning', 'årsbokslut'],
		},
		{ key: 'deklaration', whitelist: ['deklaration', 'deklarera'], blacklist: [] },
		{ key: 'fakturering', whitelist: ['fakturering', 'faktura', 'fakturera'], blacklist: [] },
		{ key: 'lön', whitelist: ['lön'], blacklist: ['lönsam'] },
		{ key: 'moms', whitelist: ['moms'], blacklist: [] },
		{ key: 'skatt', whitelist: ['skatt'], blacklist: [] },
		{ key: 'årsredovisning / bokslut', whitelist: ['bokslut', 'årsredovisning'], blacklist: [] },
	];
	const defaultFilter: Choice[] = [];
	const [selectedFilterChoices, setSelectedFilterChoices] = useState(defaultFilter);

	const [selectedDistance, setSelectedDistance] = useState<string | '50'>('50');
	const distanceOptions: SelectOption[] =
		['10', '30', '50']?.map((distance) => ({
			label: t`${distance} km`,
			id: distance,
			value: distance,
		})) ?? [];
	const selectedDistanceOption = distanceOptions.filter((option) => option.value === selectedDistance)?.[0] || null;

	const [useMyPosition, setUseMyPosition] = useState<boolean>(false);
	const [geoLocationError, setGeoLocationError] = useState<boolean>(false);
	const [accountantsByLocation, setAccountantsByLocation] = useState<Accountant[] | undefined>(undefined);

	const [paginatedAccountants, setPaginatedAccountants] = useState<Accountant[] | null>(null);
	const [searchResult, setSearchResult] = useState<Accountant[]>([]);

	const [debouncedSetSearchTerm] = useState(() => debounce(setSearchTerm, 1000));
	const router = useRouter();
	const query = router.query;
	const { hasSubMenu } = useSubMenu();
	const unFilteredSearchResult = useMyPosition && accountantsByLocation ? accountantsByLocation : accountants;

	useEffect(() => {
		if (query.address) {
			const address = Array.isArray(query.address) ? query.address[0] : query.address;
			setSearchTerm(address);
			setCurrentPage(1);
			setUseMyPosition(false);
		} else {
			setSearchTerm('');
			setSearchField('');
		}
	}, [query]);

	useEffect(() => {
		if (!searchTerm) return;
		const query = { ...router.query, address: searchTerm };
		router.replace({ pathname: router.pathname, query }, undefined, { scroll: false });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm]);

	useEffect(() => {
		if (unFilteredSearchResult) {
			const withinDistance = unFilteredSearchResult.filter((a) => a.distance < Number(selectedDistance) * 1000);
			if (selectedFilterChoices.length === 0) {
				setSearchResult(withinDistance);
			} else {
				const filteredResult = withinDistance.filter(
					(a) => a.description && filterMatch(selectedFilterChoices, a.description),
				);
				setSearchResult(filteredResult);
			}
			setCurrentPage(1);
		}
	}, [unFilteredSearchResult, setSearchResult, selectedFilterChoices, selectedDistance]);

	useEffect(() => {
		setTotalPages(Math.ceil(searchResult.length / MAX_PER_PAGE));
	}, [searchResult]);

	const handlePaginationAndScroll = (event: any) => {
		const element = document.getElementById('accountants');
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
		handlePagination(event);
	};
	const handlePagination = (event: any) => {
		if (event.currentTarget.value === 'forward') {
			setCurrentPage(currentPage < totalPages ? currentPage + 1 : currentPage);
		} else if (event.currentTarget.value === 'back') {
			setCurrentPage(currentPage !== 1 ? currentPage - 1 : currentPage);
		}
	};

	useEffect(() => {
		if (searchResult.length <= MAX_PER_PAGE) {
			setPaginatedAccountants(searchResult);
			return;
		}
		if (currentPage === 1) {
			setPaginatedAccountants(searchResult.slice(0, MAX_PER_PAGE));
			return;
		}
		const startIndex = (currentPage - 1) * MAX_PER_PAGE;
		const endIndex = MAX_PER_PAGE * currentPage;

		setPaginatedAccountants(searchResult.slice(startIndex, endIndex));
	}, [currentPage, searchResult]);

	const handleSearch = (input: string) => {
		debouncedSetSearchTerm(input);
		setSearchField(input);
	};

	const handlePositionSearch = async (position: Position) => {
		setUseMyPosition(true);
		const result = await fetchApi('accountants-v1', position.latitude, position.longitude);
		setSelectedFilterChoices([]);
		setAccountantsByLocation(result.body);
		setCurrentPage(1);
		await router.replace({ pathname: router.pathname, query: '' }, undefined, { scroll: false });
	};

	const clearPositionSearch = async () => {
		setUseMyPosition(false);
		await router.replace({ pathname: router.pathname, query: '' }, undefined, { scroll: false });
		setAccountantsByLocation(undefined);
		setSelectedFilterChoices([]);
	};

	return (
		<PageLayout subMenu={subMenu} meta={meta} exits={contact}>
			{blok.header?.map((blok: any) => {
				if (searchAddress && accountants.length > 0) {
					const headerBlock = { ...blok };
					headerBlock.h1Part1 = 'Redovisningsbyråer i';
					headerBlock.h1Part2 = capitalizeEachWord(searchAddress);
					return <DynamicComponent key={headerBlock._uid} blok={headerBlock} />;
				} else {
					return <DynamicComponent key={blok._uid} blok={blok} />;
				}
			})}
			<Section id="result" className={cls(hasSubMenu && styles.hasSubMenu)}>
				<div className={styles.filterContainer}>
					{blok?.subTitle && (
						<Heading className={styles.subTitle} as="h2" data-testid="filter-header-h2" title={blok.subTitle} />
					)}
					<Grid className={styles.searchFieldContainer} columns={{ base: 12 }} colGap="sm" rowGap="sm">
						<Grid.Item colStart={{ base: 2, lg: 1 }} colEnd={{ base: 12, lg: 8 }}>
							<div>
								<TextField
									id="find-accountants-search"
									bordered
									value={searchField}
									onChange={(e) => handleSearch(e.target.value)}
									type="search"
									title={useMyPosition ? t`Din position` : t`Sök på ort, adress, plats`}
									disabled={useMyPosition}
								/>
								{useMyPosition && geoLocationError && (
									<div className={styles.geolocationError}>{t`Platsåtkomst har nekats`}</div>
								)}
							</div>
						</Grid.Item>
						<Grid.Item colStart={{ base: 12, lg: 8 }} colEnd={{ base: 13, lg: 9 }} className={styles.iconContainer}>
							<GeoLocationSearch
								useMyPosition={useMyPosition}
								setUseMyPosition={setUseMyPosition}
								geoLocationError={geoLocationError}
								setGeoLocationError={setGeoLocationError}
								onClick={() => setSearchField('')}
								onSearch={handlePositionSearch}
								clearPositionSearch={clearPositionSearch}
							/>
						</Grid.Item>
						<Grid.Item colStart={{ base: 2, lg: 9 }} colEnd={{ base: 12, lg: 13 }}>
							<Select
								data={distanceOptions}
								onSelect={(value) => setSelectedDistance(value)}
								title={t`Sökradie`}
								aria-label={t`Sökradie`}
								selected={selectedDistanceOption}
								onClear={() => setSelectedDistance('50')}
								id="find-accountants-distance"
							/>
						</Grid.Item>
						<Grid.Item colSpan={{ base: 12 }} className={styles.multiChoiceContainer}>
							{unFilteredSearchResult.length > 0 && (
								<>
									<MultiChoiceFilter
										title={t`Vad behöver du hjälp med?`}
										text={t`Visa endast byråer med följande erbjudande i sin beskrivning`}
										selectedChoices={selectedFilterChoices}
										setSelectedChoices={setSelectedFilterChoices}
										choices={filterChoices}
									/>
								</>
							)}
						</Grid.Item>
					</Grid>
				</div>
				<div id={'accountants'} />
				{!!paginatedAccountants?.length && (
					<div className={styles.accountantCards}>
						<div className={styles.paginationTop}>
							<Pagination onClick={handlePagination} currentPage={currentPage} totalPages={totalPages} />
						</div>

						<Grid columns={{ base: 1, md: 2, xl: 3, xxl: hasSubMenu ? 3 : 4 }} colGap="xl" rowGap="xl">
							{paginatedAccountants?.map((accountant, index: number) => {
								return (
									<Grid.Item key={index}>
										<AccountantCard accountant={accountant} key={`accountant-${accountant.id}`} />
									</Grid.Item>
								);
							})}
						</Grid>

						<div className={styles.paginationBottom}>
							<Pagination onClick={handlePaginationAndScroll} currentPage={currentPage} totalPages={totalPages} />
						</div>
					</div>
				)}
				<div className={styles.footer}>
					<Grid
						className={styles.gridContainer}
						columns={{ base: 1, lg: blok?.footer?.length || 0 }}
						colGap="md"
						rowGap="xl"
					>
						{blok.footer?.map((blok) => (
							<div key={blok._uid}>
								<BrandLine className={styles.brandLine} />
								<DynamicComponent blok={blok} />
							</div>
						))}
					</Grid>
				</div>
			</Section>
			<AccountantCityList cities={cities} onLinkClick={() => setSearchField('')} />
		</PageLayout>
	);
};
