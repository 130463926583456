import { Text } from '@/atoms';
import { renderRichText } from '@/bloks/RichText';
import { CodeStoryblok, RichtextStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { cls } from '@/utils';
import React, { useEffect, useRef, useState } from 'react';
import styles from './Code.module.scss';

interface BlokProps {
	blok: CodeStoryblok;
}

interface CodeProps {
	codeDescription?: RichtextStoryblok;
	code: any;
}

const blokProps = ({ blok }: BlokProps): CodeProps => ({
	codeDescription: blok.codeDescription,
	code: blok.code,
});

interface CodeContent {
	type: string;
	content: [any];
}

interface Tab {
	tabName: string;
	content: CodeContent;
}

const buildCodeContent = (item: any): CodeContent => {
	return { type: 'doc', content: [item] };
};

const buildCodeTabs = (code: any): Tab[] => {
	return code.content.map((item: any) => ({
		tabName: formatTabName(item?.attrs?.class),
		content: buildCodeContent(item),
	}));
};

const formatTabName = (name: string): string | null => {
	if (name) {
		const sliceStart = name.indexOf('-');
		const formattedName = name.slice(sliceStart + 1);
		return formattedName.charAt(0).toUpperCase() + formattedName.slice(1);
	}
	return null;
};

export const Code = ({ codeDescription, code }: CodeProps) => {
	const tabs: Tab[] | null = code ? buildCodeTabs(code) : null;
	const [tabName, setTabName] = useState(tabs?.[0].tabName);
	const [expanded, setExpanded] = useState(false);
	const [isOverflow, setIsOverflow] = useState(false);
	const { hasSubMenu } = useSubMenu();
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const checkOverFlow = (): Element | undefined => {
			if (ref.current) {
				const elements = Array.from(ref.current.children);
				return elements.find((el) => el.id === tabName);
			}
		};
		const element = checkOverFlow();

		if (element !== undefined) {
			setIsOverflow(element.scrollHeight > element.clientHeight);
		}
	}, [tabName]);

	const tabHandler = (_e: React.MouseEvent<HTMLButtonElement>, tab: string) => {
		setTabName(tab);
		setExpanded(false);
	};

	const renderCode = () => {
		return tabs?.map((tab, index) => {
			return (
				<div
					key={index}
					className={cls(
						styles.codeContainer,
						expanded ? styles.expanded : styles.detracted,
						tab.tabName === tabName ? styles.display : styles.hidden,
					)}
					id={tab.tabName}
				>
					{renderRichText(tab.content)}
				</div>
			);
		});
	};
	return (
		<div className={cls(styles.generalContainer, hasSubMenu ? styles.subMenuContainer : styles.container)}>
			{codeDescription && <Text as="div">{renderRichText(codeDescription)}</Text>}
			{tabs && (
				<>
					<div className={styles.tabs}>
						{tabs.map((tab, index) => (
							<button
								key={index}
								onClick={(e) => tabHandler(e, tab.tabName)}
								className={cls(styles.tabButton, tab.tabName === tabName && styles.active)}
							>
								{tab.tabName}
							</button>
						))}
					</div>
					<div ref={ref}>
						{renderCode()}
						{isOverflow && (
							<div className={styles.showMoreContainer}>
								<button onClick={() => setExpanded(!expanded)} className={styles.showMoreButton}>
									{expanded ? 'Show Less' : 'Show More'}
								</button>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

Code.blokProps = blokProps;
