import { BrandLine, Card, Grid, Heading } from '@/atoms';
import { PopUpButton } from '@/bloks';
import { Blok } from '@/bloks/Blok';
import { PopUpButtonProps } from '@/bloks/Button/PopUpButton';
import { HeaderCommon, HeaderCommonProps } from '@/bloks/Other';
import { CardLargeStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './CardLarge.module.scss';

interface BlokProps {
	blok: CardLargeStoryblok;
	meta?: {
		layout?: CardLargeProps['layout'];
		zone?: number;
	};
}

const blokProps = ({ blok, meta }: BlokProps): CardLargeProps => ({
	image: blok.image,
	header: blok.header?.[0] && HeaderCommon.blokProps({ blok: blok.header[0] }),
	layout: meta?.layout,
	_editable: blok._editable,
	zone: meta?.zone,
	popUpButton: blok.popupButton?.[0] && PopUpButton.blokProps({ blok: blok.popupButton[0] }),
});

export interface CardLargeProps {
	image: ImageAssetProps;
	header: HeaderCommonProps;
	layout?: 'image-left' | 'image-right';
	/**
	 * Gives CardLarge a more compact design
	 */
	compact?: boolean;
	popUpButton?: PopUpButtonProps;
	_editable?: string;
	zone?: number;
}

export const CardLarge: Blok<CardLargeProps, BlokProps> = ({
	image,
	header,
	layout = 'image-right',
	compact = false,
	popUpButton,
	_editable,
	zone,
}) => {
	const { hasSubMenu } = useSubMenu();
	const title = header?.titlePart2 ? `${header?.titlePart1} ${header.titlePart2}` : header?.titlePart1;
	let contentTarget = header?.linkButtons?.length ? 'multiple interactions possible' : 'no interactions possible';
	if (header?.linkButtons?.length === 1) contentTarget = 'one interaction possible';
	return (
		<ImpressionTrackingProvider value={{ contentName: `${zone}|SectionCard`, contentPiece: title, contentTarget }}>
			<div className={cls(styles.container, hasSubMenu && styles.hasSubMenu, compact && styles.compact)}>
				<Card
					{...editableBlok({ _editable })}
					className={cls(styles.card, styles[`layout--${layout}`])}
					border={false}
					padding="none"
				>
					<Grid columns={{ base: 1, lg: 2 }} colGap="xl">
						<Grid.Item className={styles.imageColumn}>
							{image?.filename && (
								<div className={styles.image}>
									<ImageAsset
										absolute
										asset={image}
										objectFit="cover"
										fill
										objectPosition="center center"
										filters="smart"
									/>

									<div className={styles.titleMobile}>
										{header.smallTitle && (
											<Heading
												as="div"
												size="h6"
												title={header.smallTitle}
												data-testid="section-common-header-h6"
												marginBottom="xl"
												smallTitle
												applyLightnessTheme
												noId
											/>
										)}
										{header.titlePart1 && (
											<Heading
												as="div"
												size={header.titleSize}
												title={header.titlePart1}
												titlePart2={header.titlePart2}
												marginBottom="xl"
												applyLightnessTheme
												noId
											/>
										)}
									</div>

									<div className={styles.overlay} />
								</div>
							)}
						</Grid.Item>

						<Grid.Item className={styles.textColumn}>
							<div className={styles.textContainer}>
								{!header?.hideBrandline && <BrandLine className={styles.brandLine} marginBottom="xl" />}
								{header.smallTitle && (
									<Heading
										as="div"
										size="h6"
										title={header.smallTitle}
										marginBottom="xl"
										smallTitle
										className={styles.titleDesktop}
									/>
								)}
								{header.titlePart1 && (
									<Heading
										as="div"
										size={header.titleSize}
										title={header.titlePart1}
										titlePart2={header.titlePart2}
										marginBottom="xl"
										className={styles.titleDesktop}
									/>
								)}
								<HeaderCommon
									{...header}
									hideBrandline
									smallTitle=""
									titlePart1=""
									titlePart2=""
									className={styles.headerCommon}
								/>
								{popUpButton && <PopUpButton {...popUpButton} className={styles.popUpBtn} />}
							</div>
						</Grid.Item>
					</Grid>
				</Card>
			</div>
		</ImpressionTrackingProvider>
	);
};

CardLarge.blokProps = blokProps;
